import TrueFalse from '@/components/layout/Orientation/quiz-inputs/TrueFalse.vue'
import Multiple from '@/components/layout/Orientation/quiz-inputs/Multiple.vue'
import Single from '@/components/layout/Orientation/quiz-inputs/Single.vue'

const inputComponentMap = {
  single: Single,
  multiple: Multiple,
  't/f': TrueFalse,
}

export { inputComponentMap }
