import gql from 'graphql-tag'

// QUERIES

const inductions = gql`
  query Inductions($site_id: ID, $year: Int) {
    inductions(site_id: $site_id, year: $year) {
      id
      site_id
      on_site_training_done
      completed_at
      user {
        id
        first_name
        last_name
        companies {
          id
          name
        }
      }
    }
  }
`

const induction = gql`
  query Induction($id: ID!) {
    induction(id: $id) {
      id
      form_id
      user_id
      site_id
      form {
        id
        name
        sections {
          id
          form_id
          image_id
          video_id
          youtube_id
          order
          title
          content
          image {
            id
            original_name
            url
          }
          video {
            id
            original_name
            url
          }
        }
        quiz {
          id
          form_id
          title
          passing_grade
          questions {
            id
            quiz_id
            order
            text
            note
            options
          }
        }
      }
    }
  }
`

const form = gql`
  query Form {
    form {
      id
      name
      preface
      sections {
        id
        form_id
        image_id
        video_id
        youtube_id
        order
        title
        content
        image {
          id
          original_name
          url
        }
        video {
          id
          original_name
          url
        }
      }
      quiz {
        id
        form_id
        title
        passing_grade
        questions {
          id
          quiz_id
          order
          text
          note
          options
        }
      }
    }
  }
`

const editForm = gql`
  query editForm {
    editForm {
      id
      name
      preface
      sections {
        id
        form_id
        image_id
        video_id
        youtube_id
        order
        title
        content
        image {
          id
          original_name
          url
        }
        video {
          id
          original_name
          url
        }
      }
      quiz {
        id
        form_id
        title
        passing_grade
        questions {
          id
          quiz_id
          order
          text
          note
          options
        }
      }
    }
  }
`

const publishForm = gql`
  mutation PublishForm($id: ID!) {
    publishForm(id: $id) {
        id
    }
  }
`

// MUTATIONS

const markInductionOnSiteCompleted = gql`
  mutation MarkInductionOnSiteCompleted($id: ID!) {
    markInductionOnSiteCompleted(id: $id) {
      id
      site_id
      on_site_training_done
    }
  }
`

const createInduction = gql`
  mutation CreateInduction($siteId: Int!, $formId: Int!) {
    createInduction(input: { site_id: $siteId, form_id: $formId }) {
      id
      form_id
      user_id
    }
  }
`

const updateInductionSite = gql`
  mutation UpdateInductionSite($id: ID!, $siteId: Int!) {
    updateInductionSite(id: $id, site_id: $siteId) {
      site {
        id
        name
        address
        email
      }
    }
  }
`

const inductionsGQL = {
  markInductionOnSiteCompleted,
  inductions,
  induction,
  form,
  editForm,
  publishForm,
  createInduction,
  updateInductionSite,
}

export default inductionsGQL
