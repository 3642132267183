<template>
  <div class="orientation-preface">
    <h2 class="orientation-preface__title">{{$t("orientation.preface.title")}}</h2>
    <div class="orientation-preface__content" v-html="preface">
    </div>
    <div class="orientation-preface__actions">
      <button class="button button--regular" @click="$emit('start')">{{$t("common.start")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preface',
  computed: {
    preface() {
      return this.$t("orientation.preface.content")
    }
  }
}
</script>
