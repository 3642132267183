<template>
  <div>
    <div class="input-edit-container">
      <input id="true" type="radio" :name="`question-${questionId}`" :value="true" @change="handleChange" />
      <label for="true"
        ><i :class="iconClass(true)">{{ getAnswerIcon(true) }}</i> {{ $t("orientation.quiz.true") }}</label
      >
    </div>
    <div class="input-edit-container">
      <input id="false" type="radio" :name="`question-${questionId}`" :value="false" @change="handleChange" />
      <label for="false"
        ><i :class="iconClass(false)">{{ getAnswerIcon(false) }}</i> {{ $t("orientation.quiz.false") }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrueFalseEdit',
  props: {
    options: {
      type: Object,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  methods: {
    getAnswerIcon(value) {
      return value === this.options.answer ? 'done' : 'horizontal_rule'
    },
    iconClass(value) {
      return [
        'material-icons',
        {
          green: value === this.options.answer,
        },
      ]
    },
    handleChange(e) {
      const booleanValue = e.target.value === 'true'
      this.$emit('truefalse-change', booleanValue)
    },
  },
}
</script>
