<template>
  <!-- <p>Company</p> -->
  <div>
    <div class="slide-sidemenu__content__buttons">
      <button
        v-if="isEdit"
        :disabled="!emailIsValid"
        class="slide-sidemenu__content__save-button button--regular"
        @click="handleSave()"
      >
        Save
      </button>
      <button v-if="isEdit" class="slide-sidemenu__content__save-button button--outlined" @click="handleCancel()">
        Cancel
      </button>
    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Company Name</h5>
      <input
        v-if="isEdit"
        v-model="company.name"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ company.name }}</p>
    </div>
    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Email</h5>
      <input
        v-if="isEdit"
        v-model="company.email"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ company.email }}</p>
    </div>

    <hr />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import headers from '@/helpers/headers'
import validationRegex from '@/helpers/validationRegex'

export default {
  name: 'CompanySideMenu',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      nbOfContractors: 1,
      company: { name: '', email: '' },
    }
  },
  computed: {
    emailIsValid() {
      return validationRegex.email.test(this.company.email)
    },
  },
  mounted() {
    this.$globalEvents.on('side-delete-entity', (type) => {
      if (type === 'company' || type === 'companies') {
        this.deleteCompany()
      }
    })
    this.$emit('can-delete')
  },
  methods: {
    handleSave() {
      this.$emit('save')
      this.saveCompany()
    },
    handleCancel() {
      this.isEdit = false
      this.$emit('save')
    },
    deleteCompany() {
      const {
        id,
      } = this

      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteCompany($id: ID!) {
              deleteCompany(id: $id) {
                id
              }
            }
          `,
          variables: {
            id,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          this.company = { name: '', email: '' }
        })
        .catch(console.error)
    },
    saveCompany() {
      const {
        company: { name, email },
        id,
      } = this

      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCompany($id: ID!, $name: String!, $email: String!) {
              updateCompany(id: $id, input: { email: $email, name: $name }) {
                id
                name
                email
              }
            }
          `,
          variables: {
            email,
            name,
            id,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          const {
            data: {
              updateCompany: { email: companyEmail, id: companyId, name: companyName },
            },
          } = res

          this.company.name = companyName
          this.company.email = companyEmail
          this.company.id = companyId
        })
        .catch(console.error)
    },
  },
  apollo: {
    company: {
      query: gql`
        query Company($id: ID!) {
          company(id: $id) {
            name
            email
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        }
      },
      context: {
        headers,
      },
      update: data => data.company,
    },
  },
}
</script>
