<template>
  <AuthLayout>
    <h1 class="mb-100">Forgot Password</h1>

    <div v-if="emailSent">
      <i class="material-icons">done</i>
      <p>
        An email was successfully sent to <strong>{{ email.value }}</strong> to reset the password.
      </p>
    </div>
    <form v-else action="" class="auth-block__form" @submit.prevent>
      <div :class="inputContainerClass(email)">
        <label for="email">Email</label>
        <input id="email" v-model="email.value" type="email" @input="validateInput('email', 'email')" />
        <p v-if="!email.isValid" class="input-container__feedback">
          {{ email.feedback }}
        </p>
      </div>
    </form>
    <div class="auth-block__form__bottom">
      <RouterLink :to="{ name: 'signin' }">Sign in</RouterLink>
      <button v-if="emailSent" type="submit" class="button button--regular" @click="sendEmail">SEND AGAIN</button>
      <button v-else :disabled="!allFieldsValid" type="submit" class="button button--regular" @click="sendEmail">
        SEND
      </button>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout.vue'
import auth from '@/gql/auth'
import validateField from '@/helpers/validateField'

export default {
  name: 'PasswordReset',
  components: {
    AuthLayout,
  },
  data() {
    return {
      email: {
        value: '',
        isValid: null,
        feedback: '',
      },
      emailSent: false,
    }
  },
  computed: {
    allFieldsValid() {
      const fields = Object.values({ ...this.$data }).filter(field => field.isValid !== undefined)
      return fields.every(field => field.isValid)
    },
  },
  methods: {
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container' : 'input-container --error'
    },
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    sendEmail() {
      const {
        email: { value: email },
      } = this

      this.$apollo
        .mutate({
          mutation: auth.forgotPassword,
          variables: {
            email,
          },
        })
        .then(() => {
          this.emailSent = true
        })
        .catch(console.error)
    },
  },
}
</script>
