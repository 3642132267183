import portableApollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'
import orientationGQL from '@/gql/orientation'

export default function ({ commit }, section) {
  const { instance: $apollo } = portableApollo

  return new Promise((res, rej) => {
    if (section.isNew) {
      commit('deleteStateSection', section.id)
      res()
    }
    $apollo
      .mutate({
        mutation: orientationGQL.deleteFormSection,
        variables: { id: section.id },
        context: { headers },
      })
      .then(resp => {
        commit('deleteStateSection', resp.data.deleteFormSection.id)
        res()
      })
      .catch(err => {
        rej(err)
      })
  })
}
