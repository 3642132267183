import TrueFalseEdit from '@/components/layout/Orientation/quiz-inputs/edit/TrueFalseEdit.vue'
import MultipleEdit from '@/components/layout/Orientation/quiz-inputs/edit/MultipleEdit.vue'
import SingleEdit from '@/components/layout/Orientation/quiz-inputs/edit/SingleEdit.vue'

const inputEditComponentMap = {
  single: SingleEdit,
  multiple: MultipleEdit,
  't/f': TrueFalseEdit,
}

export { inputEditComponentMap }
