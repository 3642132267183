import gql from 'graphql-tag'

const queries = {
  SITE_QUERY: gql`
    query SearchSites($search: String!, $page: Int!) {
      sites(search: $search, page: $page) {
        data {
          id
          name
          active_managers {
            id
            first_name
            last_name
          }
        }
      }
    }
  `,
  CONTRACTOR_QUERY: gql`
    query SearchbarContractors($search: String!, $page: Int!) {
      contractors(search: $search, page: $page) {
        data {
          id
          first_name
          last_name
          is_contractor
          companies {
            name
          }
          inductions {
            completed_at
            on_site_training_done
            site {
              name
            }
          }
        }
      }
    }
  `,
  COMPANY_QUERY: gql`
    query SearchCompanies($search: String!, $page: Int!) {
      companies(search: $search, page: $page) {
        data {
          id
          name
        }
      }
    }
  `,
  MANAGER_QUERY: gql`
    query SearchManagers($search: String!, $page: Int!) {
      siteManagers(search: $search, page: $page) {
        data {
          id
          first_name
          last_name
          actives_managing_sites {
            id
            name
          }
        }
      }
    }
  `,
}

const searchbarQueryMap = {
  sites: queries.SITE_QUERY,
  siteManagers: queries.MANAGER_QUERY,
  companies: queries.COMPANY_QUERY,
  contractors: queries.CONTRACTOR_QUERY,
}

export default searchbarQueryMap
