<template>
      <Card :title="$t('contractor.dashboard.addToSite')" class="-larger">
        <div class="card__dropdowns">
          <SearchDropdown label="Sites" :index="0" @input="handleSiteInput" />
        </div>
        <button class="button button--plus" :disabled="!currentSite" @click="addToSite">
          <i class="material-icons">add_circle</i>
        </button>
      </Card>
</template>

<script>
import Card from "@/components/_parts/Card.vue";
import SearchDropdown from "@/components/_parts/SearchDropdown.vue";

export default {
  name: "ContractorAddSite",
  components: {Card, SearchDropdown},
  data () {
    return {
      currentSite: null
    }
  },
  methods: {
    handleSiteInput (site, id) {
      this.currentSite = id;
    },
    addToSite () {
      this.$store.dispatch('attachSite', {id: this.$store.state.user.id, site_id: this.currentSite }).then(() => {
        window.location.reload();
      })
    }
  }
}
</script>

<style scoped>

</style>
