import SiteManagerForm from '../components/_parts/SiteManagerForm.vue'
import CompanyForm from '../components/_parts/CompanyForm.vue'
import SiteForm from '../components/_parts/SiteForm.vue'

import CompanySideMenu from '../components/_parts/SideMenuForms/CompanySideMenu.vue'
import SiteSideMenu from '../components/_parts/SideMenuForms/SiteSideMenu.vue'
import SiteManagerSideMenu from '../components/_parts/SideMenuForms/SiteManagerSideMenu.vue'
import ContractorSideMenu from '../components/_parts/SideMenuForms/ContractorSideMenu.vue'

export const quickActionTypes = {
  'ADD A SITE MANAGER': 'ADD A SITE MANAGER',
  'ADD A COMPANY': 'ADD A COMPANY',
  'ADD A SITE': 'ADD A SITE',
}

export const quickActionTypesComponentMap = {
  [quickActionTypes['ADD A SITE MANAGER']]: SiteManagerForm,
  [quickActionTypes['ADD A COMPANY']]: CompanyForm,
  [quickActionTypes['ADD A SITE']]: SiteForm,
}

export const entityTypes = {
  company: 'company',
  companies: 'companies',
  site: 'site',
  sites: 'sites',
  siteManager: 'siteManager',
  siteManagers: 'siteManagers',
  contractor: 'contractor',
  contractors: 'contractors',
}

export const entityTypesComponentMap = {
  [entityTypes.company]: CompanySideMenu,
  [entityTypes.companies]: CompanySideMenu,
  [entityTypes.site]: SiteSideMenu,
  [entityTypes.sites]: SiteSideMenu,
  [entityTypes.siteManager]: SiteManagerSideMenu,
  [entityTypes.siteManagers]: SiteManagerSideMenu,
  [entityTypes.contractor]: ContractorSideMenu,
  [entityTypes.contractors]: ContractorSideMenu,
}
