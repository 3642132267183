<template>
  <div class="quick-actions">
    <h2 class="slim-title">Quick Actions</h2>
    <div class="flex-card-container">
      <Card
        v-for="(text, title) in cards"
        :key="title"
        :title="title"
        :class="{ 'card--focused': focusedCard === title }"
      >
        <p v-if="$device.desktop" class="card__text">{{ text }}</p>
        <button v-if="focusedCard !== title" class="button button--plus" @click="focusedCard = title">
          <i class="material-icons">add_circle</i>
        </button>
        <button v-else class="button button--plus" @click="closeActionBlock">
          <i class="material-icons white">remove_circle</i>
        </button>
      </Card>
    </div>
    <ActionBlock
      v-if="focusedCard !== null"
      :card-text="cards[focusedCard]"
      :card="focusedCard"
      :form-site-id="siteId"
      @close="closeActionBlock"
      @add-manager="addManager"
    />
  </div>
</template>

<script>
import Card from '../_parts/Card.vue'
import ActionBlock from './ActionBlock.vue'

export default {
  name: 'QuickActions',
  components: {
    Card,
    ActionBlock,
  },
  data() {
    return {
      focusedCard: null,
      cards: {
        'ADD A SITE MANAGER': 'Add a manager to a blank site, replace an existing manager or create a new manager.',
        'ADD A COMPANY': 'Add a company so that contractors can identify themselves during sign up.',
        'ADD A SITE': 'Add a site to start business there.',
      },
      siteId: null,
    }
  },
  created() {
    this.$globalEvents.on('edit-site-manager', siteId => {
      this.$globalEvents.emit('close-menu')
      window.scrollTo(0, 0);
      this.focusedCard = null
      this.siteId = siteId
      this.focusedCard = 'ADD A SITE MANAGER'
    })
  },
  methods: {
    closeActionBlock() {
      this.focusedCard = null
      this.siteId = null
    },
    addManager() {
      this.focusedCard = 'ADD A SITE MANAGER'
    },
  },
}
</script>
