export default Vue => {
  const eventVM = new Vue({
    name: 'GlobalEvents',
  })

  Vue.prototype.$globalEvents = {
    on: eventVM.$on.bind(eventVM),
    once: eventVM.$once.bind(eventVM),
    emit: eventVM.$emit.bind(eventVM),
  }
}
