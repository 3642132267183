<template>
  <div>
    <!-- STEP 1 -->
    <div v-if="currentStep === 1" class="quick-actions__form-wrapper__step">
      <h4>1. Choose a site to link</h4>
      <SearchDropdown label="Site" @input="handleSiteChoice" />
      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--regular" :disabled="isNextDisabled" @click="next">Next</button>
      </div>
    </div>

    <!-- STEP 2 -->
    <div v-if="currentStep === 2" class="quick-actions__form-wrapper__step">
      <div class="quick-actions__form-wrapper__flex">
        <p class="quick-actions__form-wrapper__title">2. Add a site manager</p>
        <div class="quick-actions__form-wrapper__step--alert">
          <i class="material-icons">info</i>
          <p>{{ siteManagerMessage }}</p>
        </div>
      </div>
      <p class="mb-30">
        <strong>{{ step1.site }}</strong>
      </p>
      <p v-for="manager in step2.siteManagers" :key="manager.id" class="quick-actions__form-wrapper__manager">
        <strong>{{ manager.name }}</strong>
        <button @click="deleteModal = manager.id" class="quick-actions__form-wrapper__button">
          <i class="material-icons">delete</i>
        </button>
        <DeleteModale v-if="deleteModal === manager.id" :site-name="step1.site" :site-manager="manager.name" @cancel="deleteModal = null" @confirm="deleteManager"/>
      </p>
      <p v-if="step2.siteManagers.length === 0">-</p>

      <div class="quick-actions__form-wrapper__step__buttons">
        <div>
          <button class="button button--outlined" @click="prev">Previous</button>
        </div>
        <div>
          <button class="button button--regular" @click="existingManager">
            {{ existingManagerButtonLabel }}
          </button>
          <button class="button button--regular" @click="newManager">
            {{ newManagerButtonLabel }}
          </button>
        </div>
      </div>
    </div>

    <!-- STEP 3 -->
    <div v-if="currentStep === 3" class="quick-actions__form-wrapper__step">
      <h4>3. {{ step3Title }}</h4>

      <div v-if="step3.isNew">
        <div class="quick-actions__form-wrapper__step__inputs">
          <div class="input-container">
            <label for="newManagerFirstName">First Name</label>
            <input id="newManagerFirstName" v-model="step3.manager.first_name.value" type="text" />
          </div>
          <div class="input-container">
            <label for="newManagerLastName">Last Name</label>
            <input id="newManagerLastName" v-model="step3.manager.last_name.value" type="text" />
          </div>
        </div>
        <div class="quick-actions__form-wrapper__step__inputs">
          <div :class="inputContainerClass">
            <label for="newManagerEmail">Email</label>
            <input id="newManagerEmail" v-model="step3.manager.email.value" type="email" @input="validateInput()" />
            <p v-if="!step3.manager.email.isValid" class="input-container__feedback">
              {{ step3.manager.email.feedback }}
            </p>
          </div>
        </div>
      </div>
      <SearchDropdown v-else label="Manager" gql-model="siteManagers" @input="handleManagerChoice" />

      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--outlined" @click="prev">Previous</button>
        <button class="button button--regular ml-15" :disabled="isConfirmDisabled" @click="saveManager(step3.isNew)">
          Confirm
        </button>
      </div>
    </div>

    <!-- STEP 4 -->
    <div v-if="currentStep === 4" class="quick-actions__form-wrapper__step">
      <h4>4. Review the choice</h4>
      <!-- ICONE CHECKED -->
      <p class="mb-10"><i class="material-icons">done</i></p>

      <p class="strong">
        <strong>{{ step3.manager.first_name.value }} {{ step3.manager.last_name.value }}</strong>
      </p>
      <p class="slim">{{ confirmationMessage }}</p>
      <p class="mt-15">{{ step1.site }}</p>

      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--outlined" @click="$emit('close')">Close window</button>
        <button v-if="!step3.isNew" class="button button--regular ml-15" @click="seeManager">
          See manager profile
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchDropdown from '@/components/_parts/SearchDropdown.vue'
import siteGQL from '@/gql/site'
import siteManagerGQL from '@/gql/site-manager'
import headers from '@/helpers/headers'
import validateField from '@/helpers/validateField'
import DeleteModale from "@/components/layout/DeleteModale.vue";

export default {
  name: 'SiteManagerForm',
  components: {
    DeleteModale,
    SearchDropdown,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      deleteModal: null,
      currentStep: 1,
      step1: {
        site: 'Site',
        id: null,
      },
      step2: {
        siteManagers: [],
        noManagerMessage: 'This site does not have a site manager yet.',
        hasManagerMessage:
          'This site already has one or several managers. You can manage their role below or in the site’s profile.',
      },
      step3: {
        isNew: false,
        manager: {
          first_name: { value: '' },
          last_name: { value: '' },
          id: null,
          email: { value: '', isValid: null, feedback: '' },
        },
      },
    }
  },
  computed: {
    inputContainerClass() {
      return this.step3.manager.email.isValid !== false ? 'input-container' : 'input-container --error'
    },
    siteManagerMessage() {
      return this.step2.siteManagers.length > 0 ? this.step2.hasManagerMessage : this.step2.noManagerMessage
    },
    existingManagerButtonLabel() {
      return 'Add existing manager'
    },
    newManagerButtonLabel() {
      return 'Add new manager'
    },
    step3Title() {
      return this.step3.isNew ? 'Create a new manager' : 'Choose an existing manager'
    },
    isNextDisabled() {
      return this.step1.site === 'Site'
    },
    isConfirmDisabled() {
      let isComplete
      this.step3.isNew
        ? (isComplete = this.step3.manager.first_name.value !== '' && this.step3.manager.email.isValid)
        : (isComplete = this.step3.manager.first_name.value !== '')
      return !isComplete
    },
    confirmationMessage() {
      return this.step3.isNew
        ? 'A confirmation email has been sent to the user. It will be possible to see their profile once the email is verified and the user has created their account.'
        : 'Is now a manager of'
    },
  },
  watch: {
    siteId(newSiteId) {
      this.switchSite(newSiteId)
    },
  },
  mounted() {
    if (this.siteId) {
      this.switchSite(this.siteId)
    }
  },
  methods: {
    deleteManager() {
      const id = this.deleteModal;
      this.$apollo
          .query({
            query: siteGQL.detachManager,
            variables: {
              siteId: this.step1.id,
              managerId: id,
            },
            context: {
              headers,
            },
          })
          .then(() => {
            const index = this.step2.siteManagers.findIndex(manager => manager.id === id);
            this.step2.siteManagers.splice(index, 1);
            this.$globalEvents.emit('reload-menu')
            this.deleteModal = null;
          })
    },
    switchSite(siteId) {
      this.currentStep = 1
      this.step1.id = siteId
      this.getExistingSite().then(() => {
        this.next()
      })
    },
    next() {
      // COMPLEMENTARY LOGIC
      if (this.currentStep === 1) {
        this.getExistingManager()
        return
      }

      this.currentStep++
    },
    prev() {
      if (this.currentStep === 3) {
        this.step3.manager = {
          first_name: { value: '' },
          last_name: { value: '' },
          id: null,
          email: { value: '', isValid: null, feedback: '' },
        }
      }
      this.currentStep--
    },
    handleSiteChoice(value, id) {
      this.step1.site = value
      this.step1.id = id
    },
    handleManagerChoice(value, id) {
      const [firstName, lastName] = value.split(' ')
      this.step3.manager.first_name.value = firstName
      this.step3.manager.last_name.value = lastName
      this.step3.manager.id = id
    },
    validateInput() {
      const { isValid, feedback } = validateField(this.step3.manager.email.value, 'email')
      this.step3.manager.email.isValid = isValid
      this.step3.manager.email.feedback = feedback
    },
    newManager() {
      this.next()
      this.step3.isNew = true
    },
    existingManager() {
      this.next()
      this.step3.isNew = false
    },
    saveManager(isNew = false) {
      this.next()

      if (isNew) {
        this.inviteManager()
      } else {
        this.assignManager()
      }
      this.$globalEvents.emit('reload-menu');
    },
    seeManager() {
      this.$globalEvents.emit('open-menu', 'siteManager', this.step3.manager.id)
    },
    inviteManager() {
      const {
        step1: { id: siteId },
        step3: {
          manager: {
            first_name: { value: firstName },
            last_name: { value: lastName },
            email: { value: email },
          },
        },
      } = this

      this.$apollo
        .mutate({
          mutation: siteManagerGQL.createInvitation,
          variables: {
            email,
            firstName,
            lastName,
            siteId,
          },
          context: {
            headers,
          },
        })
        // .then(res => {
        //   // this.step3.manager.id
        // })
        .catch(console.error)
    },
    assignManager() {
      const {
        step3: {
          manager: { id: managerId },
        },
        step1: { id: siteId },
      } = this

      this.$apollo
        .mutate({
          mutation: siteGQL.assignManager,
          variables: {
            siteId,
            managerId,
          },
          context: {
            headers,
          },
        })
        .catch(console.error)
    },
    getExistingManager() {
      const {
        step1: { id: siteId },
      } = this

      this.$apollo
        .query({
          query: siteGQL.getActiveManager,
          variables: {
            siteId,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          const activeManagers = res.data.site.active_managers;
          if (!activeManagers || activeManagers.length === 0 ) {
            this.currentStep += 1
            return
          }

          this.step2.siteManagers = activeManagers.map(manager => {
            return {
              id: manager.id,
              name: `${manager.first_name} ${manager.last_name}`,
            }
          })

          this.currentStep += 1
        })
        .catch(console.error)
    },
    getExistingSite() {
      return new Promise((resolve, reject) => {
        const { id } = this.step1

        this.$apollo
          .query({
            query: siteGQL.getSiteProfile,
            variables: {
              id,
            },
            context: {
              headers,
            },
          })
          .then(res => {
            const {
              site: { name },
            } = res.data

            this.step1.site = name
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
