<template>
  <div>
  <div v-if="tableData" class="admin-result-table">
    <h3 class="admin-result-table__title">
      RESULTS IN <strong>{{ searchType.toUpperCase() }}</strong>
    </h3>

    <div v-if="$device.desktop" class="admin-result-table__header">
      <h4 v-for="section in tableSections[searchType]" :key="section" class="admin-result-table__header__section-title">
        {{ section }}
      </h4>
    </div>
    <div class="admin-result-table__results">
      <AdminResult
        v-for="(result, index) in tableData"
        :key="index"
        class="admin-result-table__result"
        :result="result"
        :account-type="searchType"
      />
    </div>
  </div>
  <button class="button button--regular -mt" :disabled="!enabledButton" @click="showMore">See more</button>
  </div>
</template>

<script>
import AdminResult from './AdminResult.vue'
import searchbarQueryMap from '@/gql/searchbar'
import formatTableData from '@/helpers/formatTableData'
import headers from '@/helpers/headers'

export default {
  name: 'AdminResultTable',
  components: {
    AdminResult,
  },
  props: {
    searchType: {
      type: String,
      required: true,
    },
    searchString: {
      type: String,
      default: '',
    },
    enabledButton: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      tableSections: {
        contractors: ['Contractor Name', 'Company', 'Date of Completion - Online Orientation', 'On-Site Training Status'],
        companies: ['Company Name'],
        siteManagers: ['Site Manager Name', 'Site Names'],
        sites: ['Site Name', 'Site Managers'],
      },
      data: {},
    }
  },
  computed: {
    tableData() {
      if (this.data === {}) return

      return formatTableData(this.searchType, this.data)
    },
  },
  watch: {
    async searchString() {
      await this.$apollo.queries.data.refetch({
        page: 1,
        search: `${this.searchString}%`,
      })
    },
    tableData() {
      this.$emit('data-updated')
    }
  },
  mounted() {
    this.$globalEvents.on('reload-search-results', this.reload)
    this.$emit('enable-button')
  },
  methods: {
    async reload(type = null) {
      if (!type || type === this.searchType) {
        await this.$apollo.queries.data.refetch({
          page: 1,
          search: `${this.searchString}%`,
        })
        this.$emit('reset-search')
      }
    },
    async showMore () {
      this.$emit('increase-page');
      await this.$nextTick();
      await this.$apollo.queries.data.fetchMore({
        variables: {
          page: this.page,
          search: `${this.searchString}%`,
        },
        updateQuery: (value, { fetchMoreResult }) => {
          this.data[this.searchType] = [
            ...this.data[this.searchType],
            ...fetchMoreResult[this.searchType].data
          ];
          if (fetchMoreResult[this.searchType].data.length < 20) {
            this.$emit('disable-button')
          }
        },
      })
    }
  },
  apollo: {
    data () {
      return {
        query: () => searchbarQueryMap[this.searchType],
        variables() {
          return {
            page: 1,
            search: `${this.searchString}%`,
          }
        },
        context: {
          headers,
        },
        result(value, { previousData }) {
          this.data[this.searchType] = [ ...previousData ? previousData[this.searchType].data : [], ...value.data[this.searchType].data ];
        },
        update: (value) => {
          return { ...this.data, [this.searchType]: [ ...this.data[this.searchType] || [], ...value[this.searchType].data ] };
        },
      }
    }
  },
}
</script>
