<template>
  <AuthLayout>
    <h1 class="mb-100">Reset Password</h1>

    <form action="" class="auth-block__form" @submit.prevent>
      <div :class="inputContainerClass(password)">
        <label for="password">New password</label>
        <input id="password" v-model="password.value" type="password" @input="validateInput('password', 'password')" />
        <p v-if="!password.isValid" class="input-container__feedback">
          {{ password.feedback }}
        </p>
      </div>
    </form>
    <div class="auth-block__form__bottom">
      <RouterLink :to="{ name: 'signin' }">Sign in</RouterLink>
      <button :disabled="!allFieldsValid" type="submit" class="button button--regular" @click="resetPassword">
        RESET
      </button>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout.vue'
import auth from '@/gql/auth'
import validateField from '@/helpers/validateField'

export default {
  name: 'PasswordReset',
  components: {
    AuthLayout,
  },
  data() {
    return {
      password: {
        value: '',
        isValid: null,
        feedback: '',
      },
      email: null,
      token: null
    }
  },
  computed: {
    allFieldsValid() {
      return this.password.isValid
    },
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.token) {
        this.token = this.$route.query.token
      }
      if (this.$route.query.email) {
        this.email = this.$route.query.email
      }
    }
  },
  methods: {
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container' : 'input-container --error'
    },
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    resetPassword() {
      this.$apollo
          .mutate({
            mutation: auth.resetPassword,
            variables: {
              password: this.password.value,
              token: this.token,
              email: this.email
            },
          })
          .then(() => {
            this.$router.push({ name: 'signin', params: { changedPassword: true } })
          })
          .catch(console.error)
    }
  },
}
</script>
