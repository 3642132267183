<template>
  <nav class="contractor-mobile-nav">
    <img src="/images/oldcastle_logomark.png" alt="" />
    <button v-if="$route.name === 'orientation'" class="button button--outlined" @click="reload">Cancel</button>
    <div v-else class="landing-controls">
      <button @click="$router.replace({ name: 'logout' })"><i class="material-icons">power_settings_new</i></button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'ContractorMobileNav',
  methods: {
    reload() {
      window.location.reload()
    },
  },
}
</script>
