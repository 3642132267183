<template>
  <LoggedInLayout>
    <ManagerActions :site-id="siteId" @changeSite="changeSite" @fetchContractors="getContractors"/>
    <MobileProfile v-if="$device.phone && $store.state.managerMobileMenuOpen" />
    <ContractorSearchbar class="contractor-search mb-30" @input="userInput" :site-id="siteId"/>
    <InductionsSearchTable :search="search" :site-id="siteId"/>
  </LoggedInLayout>
</template>

<script>
import LoggedInLayout from '@/components/layout/LoggedInLayout.vue'
import InductionsSearchTable from '@/components/layout/InductionsSearchTable.vue'
import ContractorSearchbar from '@/components/_parts/ContractorSearchbar.vue'
import MobileProfile from '@/components/layout/MobileProfile.vue'
import ManagerActions from "@/components/layout/ManagerActions.vue";
import contractorGQL from "@/gql/contractor";
import headers from '@/helpers/headers'

export default {
  name: 'ManagerDashboard',
  components: {
    ManagerActions,
    LoggedInLayout,
    InductionsSearchTable,
    ContractorSearchbar,
    MobileProfile,
  },
  data() {
    return {
      search: '',
      siteId: this.$store.getters.siteId,
      contractors: []
    }
  },
  mounted() {
    this.getContractors()
  },
  methods: {
    changeSite(id) {
      this.siteId = id
    },
    userInput(value) {
      this.search = value
    },
    getContractors() {
      this.$apollo
          .query({
            query: contractorGQL.searchContractors,
            variables: {
              search: this.search,
              siteId: this.siteId
            },
            context: {
              headers,
            },
          })
          .then(({ data }) => (this.contractors = data.contractors))
          .catch(console.error)
    }
  },
}
</script>
