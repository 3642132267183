import gql from 'graphql-tag'

// QUERIES

const getActiveManager = gql`
  query GetActiveManager($siteId: ID!) {
    site(id: $siteId) {
      active_managers {
        id
        first_name
        last_name
      }
    }
  }
`

const getSiteProfile = gql`
  query GetSiteProfile($id: ID!) {
    site(id: $id) {
      id
      name
      address
      email
      managers {
        id
        managing_dates
        first_name
        last_name
      }
      active_managers {
        id
        first_name
        last_name
      }
    }
  }
`

const getSiteInfo = gql`
  query GetSiteInfo($id: ID!) {
    site(id: $id) {
      id
      name
      address
    }
  }
`

// MUTATIONS

const createSite = gql`
  mutation CreateSite($name: String!, $email: String!, $address: String!) {
    createSite(input: { name: $name, email: $email, address: $address }) {
      id
      name
      email
      address
    }
  }
`

const assignManager = gql`
  mutation AssignManager($siteId: ID!, $managerId: ID!) {
    assignManager(site_id: $siteId, manager_id: $managerId) {
      message
    }
  }
`

const detachManager = gql`
  mutation DetachManager($siteId: ID!, $managerId: ID!) {
    detachManager(site_id: $siteId, manager_id: $managerId) {
      message
    }
  }
`

const updateSite = gql`
  mutation UpdateSite($siteId: ID!, $email: String!, $name: String!, $address: String!) {
    updateSite(id: $siteId, input: { name: $name, email: $email, address: $address }) {
      id
      name
      address
      email
    }
  }
`

const siteGQL = {
  createSite,
  assignManager,
  getActiveManager,
  getSiteProfile,
  updateSite,
  getSiteInfo,
  detachManager
}

export default siteGQL
