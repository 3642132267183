<template>
  <LoggedInLayout>
    <button
      v-if="$device.desktop"
      class="button button--outlined orientation__cancel-button"
      @click="cancelOrientation"
    >
      {{ $t("common.cancel") }}
    </button>
    <h1 class="mt-50 orientation-title">{{ $t("orientation.title") }}</h1>
    <div class="orientation-container">
      <div v-if="$device.desktop" class="empty-child"></div>
      <Preface v-if="!$store.state.isOrientationStarted" class="mt-50" @start="startOrientation" />
    </div>
    <div class="orientation-container">
      <!-- <div class="side-stripe__stripe">
        <div class="line"></div>
      </div> -->
      <div class="empty-child"></div>
      <Orientation v-if="loaded" />
      <h1 v-else>{{ $t("common.loading") }}...</h1>
    </div>
  </LoggedInLayout>
</template>

<script>
import LoggedInLayout from '@/components/layout/LoggedInLayout.vue'
import Orientation from '@/components/layout/Orientation/Orientation.vue'
import Preface from '@/components/layout/Orientation/Preface.vue'

export default {
  name: 'ContractorDashboard',
  components: {
    LoggedInLayout,
    Orientation,
    Preface,
  },
  data() {
    return {
      loaded: false,
    }
  },
  mounted() {
    this.$store.dispatch('getForm').then(() => {
      this.loaded = true
      this.$store.dispatch('initOrientation')
    })
  },
  methods: {
    cancelOrientation() {
      this.$router.push({name: this.$store.getters.role})
    },
    async startOrientation() {
      await this.$store.dispatch('startOrientation')
      document.querySelector('.section-0').scrollIntoView()
    }
  },
}
</script>
