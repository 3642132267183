import './styles/app.scss'

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueRouter from 'vue-router'
import DeviceQueries from 'vue-device-queries'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { sync } from 'vuex-router-sync'
import VueI18n from 'vue-i18n'
import store from './store'
import globalEvents from './plugins/globalEvents'
import App from './App.vue'
import router from './routes/Router'
import portableApollo from '@/plugins/portableApollo'
import en from './lang/en.json'
import fr from './lang/fr.json'
import es from './lang/es.json'

Vue.use(VueI18n)

const httpOptions = {
  uri: process.env.VUE_APP_API_ENDPOINT + process.env.VUE_APP_GQL_ROUTE,
}
// Http connection to the API
const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(httpOptions),
  new BatchHttpLink(httpOptions)
)
// const uploadLink = createUploadLink({
//   uri: process.env.VUE_APP_API_ENDPOINT,
// })

// Cache implementation
const cache = new InMemoryCache()

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache,
  defaultOptions,
})

// Apollo provider
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)
Vue.use(portableApollo, apolloProvider)
Vue.use(VueRouter)
Vue.use(globalEvents)
Vue.use(DeviceQueries, {
  phone: 'max-width: 599px',
  desktop: 'min-width: 600px',
})
Vue.config.productionTip = false

sync(store, router)

fetch('/icons.svg')
  .then(res => res.text())
  .then(sprite => document.body.insertAdjacentHTML('afterbegin', sprite))

const messages = {
  en,
  fr,
  es
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

// portableApollo.init(apolloProvider)

new Vue({
  render: h => h(App),
  router,
  apolloProvider,
  store,
  i18n
}).$mount('#app')
