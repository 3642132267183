<template>
  <div class="orientation-section quiz">
    <span class="dot"></span>
    <span class="side-stripe">
      <span class="line"></span>
    </span>
    <header class="orientation-section__title">
      <h2>Final Comprehension Test</h2>

      <div class="orientation-section__edit-actions">
        <button v-if="!isEdit" class="orientation-section__edit-icon" @click="isEdit = true">
          <i class="material-icons">edit</i>
        </button>
        <button v-if="isEdit" class="button button--regular" @click="saveQuiz" :disabled="!isValid">Save changes
        </button>
      </div>
    </header>
    <div class="quiz__container">
      <div v-if="isEdit" class="quiz__question-selectors mb-25">
        <div>
          <button
            v-for="question in questionsModel"
            :key="question.id"
            :class="selectorClass(question.order)"
            @click="activeQuestion = question.order"
          >
            Q{{ question.order }}
          </button>
          <button class="button button--outlined" @click="addQuestion">+</button>
        </div>
        <button class="button button--outlined button--icon" @click="deleteQuestion">
          <i class="material-icons">delete_outline</i>
        </button>
      </div>
      <div v-if="isEdit" class="quiz__question-type-selectors mb-25">
        <button
          v-for="type in questionTypes"
          :key="type.questionType"
          :class="questionTypeSelectorClass(type.questionType)"
          @click="changeQuestionType(type.questionType)"
        >
          {{ type.label }}
        </button>
      </div>
      <template v-for="(question, index) in questionsModel">
        <div v-if="question.order === activeQuestion" :key="question.id" class="quiz__question">
          <h3 class="mb-25">{{ $t("orientation.quiz.question") }} {{ question.order }}/{{ questionsModel.length }}</h3>
          <p class="quiz__question__text mb-15">
            <input
              v-if="isEdit"
              v-model="questionsModel[index].text[currentLang]"
              type="text"
              placeholder="Type a question..."
              class="quiz__question__text__input"
            />
            <span v-else>{{ question.text[currentLang] }}</span>
          </p>
          <p v-if="isEdit" class="mb-25">
            <input
              v-model="questionsModel[index].note[currentLang]"
              type="text"
              placeholder="Type a note..."
              class="quiz__question__text__input"
            />
            <!-- {{ question.note }} -->
          </p>
          <Component
            :is="getInputComponent(question.options[currentLang].type)"
            :question="question"
            :is-answered="false"
            :answer="null"
            :is-valid="true"
            :options="isEdit ? question.options[currentLang] : getQuestionOptions(question.options[currentLang].values)"
            :question-id="question.id"
            :question-index="index"
            :is-edit="isEdit"
            :current-lang="currentLang"
            @truefalse-change="handleTrueFalseChange"
            @single-change="handleSingleChange"
          />
        </div>
      </template>
      <div v-if="!isEdit" class="quiz__container__actions">
        <button v-if="activeQuestion !== 1" class="button button--outlined" @click="activeQuestion -= 1">
          Previous Question
        </button>
        <button v-if="!isLastQuestion" class="button button--regular ml-10" @click="activeQuestion += 1">
          Next question
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {inputEditComponentMap} from '@/components/layout/Orientation/quiz-inputs/edit'
import {inputComponentMap} from '@/components/layout/Orientation/quiz-inputs'

export default {
  name: 'QuizEdit',
  props: {
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      activeQuestion: 1,
      isEdit: false,
      questionsModel: [...this.$store.state.form.quiz.questions],
      questionTypes: [
        {label: 'One Choice', questionType: 'single'},
        {label: 'Multiple', questionType: 'multiple'},
        {label: 'True/False', questionType: 't/f'}
      ],
      defaultTypeOptionObj: {
        't/f': {
          type: 't/f',
          answer: true
        },
        multiple: {
          type: 'multiple',
          values: []
        },
        single: {
          type: 'single',
          values: []
        }
      }
    }
  },
  computed: {
    activeQuestionIndex() {
      const index = this.questionsModel.findIndex(question => question.order === this.activeQuestion)
      return index >= 0 ? index : 0
    },
    activeQuestionObj() {
      return this.questionsModel[this.activeQuestionIndex]
    },
    activeQuestionType() {
      return this.activeQuestionObj && this.activeQuestionObj.options[this.currentLang].type ?
        this.activeQuestionObj.options[this.currentLang].type.toLowerCase() :
        null
    },
    isLastQuestion() {
      return this.activeQuestion === this.questionsModel.length
    },
    isValid() {
      let isValid = true
      Object.values(this.questionsModel).forEach(question => {
        Object.values(question.options).forEach(options => {
          if (options.type.toLowerCase() !== 't/f') {
            if (!options.values.some(({valid}) => valid)) isValid = false
          }
        })
      })
      return isValid
    }
  },
  methods: {
    addQuestion() {
      const biggestId = Math.max(
        ...this.questionsModel.map(question => {
          return question.id
        })
      )
      const lastOrder = this.questionsModel.length > 0 ?
        this.questionsModel[this.questionsModel.length - 1].order :
        0

      const newSection = {
        id: biggestId + 1,
        note: {},
        options: {
          en: {type: 'single', values: []},
          fr: {type: 'single', values: []},
          es: {type: 'single', values: []},
        },
        order: lastOrder + 1,
        quiz_id: this.$store.state.form.quiz.id,
        text: {},
      }

      this.questionsModel = [...this.questionsModel, newSection]
    },
    deleteQuestion() {
      this.questionsModel = this.questionsModel.filter(question => question.order !== this.activeQuestion)
      this.activeQuestion--
    },
    saveQuiz() {
      if (this.isValid) {
        this.$store
          .dispatch('updateQuiz', this.questionsModel)
          .then(() => {
            this.isEdit = false
          })
          .catch(console.error)
      }
    },
    changeQuestionType(type) {
      const newOptions = {...this.defaultTypeOptionObj[type]}
      Object.keys(this.questionsModel[this.activeQuestionIndex].options).forEach(lang => {
        const prevOptions = {...this.questionsModel[this.activeQuestionIndex].options[lang]}

        this.questionsModel[this.activeQuestionIndex].options[lang] = {
          ...newOptions,
          prevOptions
        }
      })
    },
    handleTrueFalseChange(value) {
      this.questionsModel[this.activeQuestionIndex].options[this.currentLang].answer = value
    },
    handleSingleChange(options) {
      this.questionsModel[this.activeQuestionIndex].options[this.currentLang] = {...options}
    },
    getInputComponent(optionType) {
      if (!this.isEdit) {
        return inputComponentMap[optionType.toLowerCase()]
      }
      return inputEditComponentMap[optionType.toLowerCase()]
    },
    getQuestionOptions(values) {
      if (!values) return
      return values.map(value => {
        return value.text
      })
    },
    selectorClass(order) {
      return [
        'button',
        'mr-5',
        {
          'button--regular': order === this.activeQuestion,
          'button--outlined': order !== this.activeQuestion
        }
      ]
    },
    questionTypeSelectorClass(type) {
      return [
        'button',
        'mr-5',
        {
          'button--regular': type === this.activeQuestionType,
          'button--outlined': type !== this.activeQuestionType
        }
      ]
    }
  }
}
</script>
