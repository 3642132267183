export default {
  email: {
    empty: 'This field is required',
    invalid: 'Please enter a valid email address',
  },
  string: {
    empty: 'This field is required',
    invalid: 'Please enter a valid string format',
  },
  date: {
    empty: 'This field is requried',
  },
}
