import contractorGQL from '@/gql/contractor'
import apollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'

export default function ({}, {id, site_id}) {
    return new Promise((res, rej) => {
        const { instance: $apollo } = apollo

        $apollo
            .mutate({
                mutation: contractorGQL.attachSite,
                context: {
                    headers,
                },
                variables: { id, site_id },

            })
            .then(() => {
                res()
            })
            .catch(err => {
                rej(err)
            })
    })
}
