<template>
  <AuthLayout> </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout.vue'

export default {
  name: 'EmailVerification',
  components: {
    AuthLayout,
  },
  data() {
    return {}
  },
  created() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      const { token } = this.$route.query
      this.$store
        .dispatch('verifyEmail', { token })
        .then(() => {
          if (this.$store.getters.role) {
            this.$router.push({ name: this.$store.getters.role })
            window.location.reload()
          } else {
            this.$router.push({ name: 'signin' })
          }
        })
        .catch(() => this.$router.push({ name: 'signin' }))
    },
  },
}
</script>
