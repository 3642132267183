<template>
  <div class="passing-block">
    <h2 class="passing-block__title">{{$t('orientation.passed.congrats')}}</h2>
    <div class="passing-block__content">
      <template v-if="!isSiteChosen">
        <h3>{{$t('orientation.passed.email')}}</h3>
        <p class="mt-20 explanation">
          {{$t('orientation.passed.chooseSite')}}
        </p>

        <SearchDropdown class="mt-50-desktop" label="Site" large @input="handleSiteChoice"/>
      </template>
      <template v-else>
        <h3>{{$t('orientation.passed.successSite')}}</h3>
        <p class="mt-20 explanation">{{$t('orientation.passed.editSite')}}</p>

        <div class="mt-25">
          <h4 class="mb-10">{{ site.name }}</h4>
          <p>{{ site.address }}</p>
        </div>
      </template>

      <div class="passing-block__actions">
        <button
          v-if="!isSiteChosen"
          :disabled="site === null || creatingInduction"
          class="button button--regular"
          @click="createInduction"
        >
          {{$t('orientation.passed.chooseSiteBtn')}}
        </button>
        <button
          v-else
          class="button button--regular"
          @click="$router.replace({ name: 'contractor', params: { justFinished: true } })"
        >
          {{$t('orientation.passed.backHome')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchDropdown from '@/components/_parts/SearchDropdown.vue'
import siteGQL from '@/gql/site'
import inductionsSQL from '@/gql/inductions'
import headers from '@/helpers/headers'

export default {
  name: 'PassingBlock',
  components: {
    SearchDropdown,
  },
  data() {
    return {
      isSiteChosen: false,
      site: null,
      creatingInduction: false,
    }
  },
  methods: {
    createInduction() {
      const { id: siteId } = this.site
      const { id: formId } = this.$store.state.form

      this.creatingInduction = true
      this.$apollo
        .mutate({
          mutation: inductionsSQL.createInduction,
          variables: {
            siteId,
            formId,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          this.isSiteChosen = true
        })
        .catch(console.error)
    },
    handleSiteChoice(value, id) {
      this.getSite(id)
    },
    getSite(id) {
      this.$apollo
        .query({
          query: siteGQL.getSiteInfo,
          variables: {
            id,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          this.site = res.data.site
        })
        .catch(console.error)
    },
  },
}
</script>
