<template>
  <AuthLayout v-if="signedUp">
    <h1 class="mb-20">You successfully signed up</h1>
    <div class="auth-block__form">
      <p style="text-align: center; line-height: 1.3">
         A validation email was sent to your inbox. Please click the link in the email to confirm.
      </p>
      <div class="auth-block__form__bottom">
        <div></div>
        <router-link :to="{ name: 'signin' }" type="submit" class="button button--regular" @click="signUp">SIGN IN</router-link>
      </div>
    </div>
  </AuthLayout>
  <AuthLayout v-else>
    <RouterLink v-if="$device.phone" class="auth-top-link" :to="{ name: 'signin' }">Sign in</RouterLink>
    <h1 class="mb-20">Sign Up</h1>

    <form action="" class="auth-block__form" @submit.prevent>
      <div class="input-wrapper--flex">
        <div :class="inputContainerClass(firstName)" v-if="!email.disabled">
          <label for="first-name">First Name</label>
          <input id="first-name" v-model="firstName.value" type="text" @input="validateInput('firstName', 'string')" />
          <p v-if="!firstName.isValid" class="input-container__feedback">
            {{ firstName.feedback }}
          </p>
        </div>
        <div :class="inputContainerClass(lastName)" v-if="!email.disabled">
          <label for="last-name">Last Name</label>
          <input id="last-name" v-model="lastName.value" type="text" @input="validateInput('lastName', 'string')" />
          <p v-if="!lastName.isValid" class="input-container__feedback">
            {{ lastName.feedback }}
          </p>
        </div>
      </div>
      <div :class="inputContainerClass(dayOfBirth)" v-if="!email.disabled">
        <label for="day-of-birth">Day of Birth</label>
        <input id="day-of-birth" v-model="dayOfBirth.value" type="date" @input="validateInput('dayOfBirth', 'date')" />
        <p v-if="!dayOfBirth.isValid" class="input-container__feedback">
          {{ dayOfBirth.feedback }}
        </p>
      </div>
      <div :class="inputContainerClass(email)">
        <label for="email">Email</label>
        <input id="email" v-model="email.value" :disabled="email.disabled" type="email" @input="validateInput('email', 'email')" />
        <p v-if="!email.isValid" class="input-container__feedback">
          {{ email.feedback }}
        </p>
      </div>
      <div :class="inputContainerClass(password)">
        <label for="password">Password</label>
        <input id="password" v-model="password.value" type="password" @input="validateInput('password', 'string')" />
        <p v-if="!password.isValid" class="input-container__feedback">
          {{ password.feedback }}
        </p>
      </div>
      <div :class="inputContainerClass(company)" v-if="!email.disabled">
        <label for="">Company</label>
        <SearchDropdown
          label="Company"
          gql-model="companies"
          @input="handleChoice"
          @blur="validateInput('company', 'string')"
        />
        <p v-if="!company.isValid" class="input-container__feedback">
          {{ company.feedback }}
        </p>
      </div>
    </form>
    <div class="auth-block__form__bottom">
      <RouterLink :to="{ name: 'signin' }">Sign in</RouterLink>
      <button :disabled="!allFieldsValid" type="submit" class="button button--regular" @click="signUp">SIGN UP</button>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout.vue'
import SearchDropdown from '@/components/_parts/SearchDropdown.vue'
import auth from '@/gql/auth'
import validateField from '@/helpers/validateField'

export default {
  name: 'SignUp',
  components: {
    AuthLayout,
    SearchDropdown,
  },
  data() {
    return {
      signedUp: false,
      // firstName: '',
      firstName: {
        value: '',
        isValid: null,
        feedback: '',
      },
      // lastName: '',
      lastName: {
        value: '',
        isValid: null,
        feedback: '',
      },
      // dayOfBirth: '',
      dayOfBirth: {
        value: '',
        isValid: null,
        feedback: '',
      },
      // email: '',
      email: {
        value: '',
        isValid: null,
        feedback: '',
        disabled: false
      },
      // password: '',
      password: {
        value: '',
        isValid: null,
        feedback: '',
      },
      company: {
        value: { name: '', id: null },
        isValid: null,
        feedback: '',
      },
      invitation_token: {
        value: null,
        isValid: true
      },
    }
  },
  computed: {
    allFieldsValid() {
      const fields = Object.values({ ...this.$data }).filter(field => field.isValid !== undefined)
      if(this.email.disabled) {
        return this.password.isValid
      }
      return fields.every(field => field.isValid)
    },
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.email) {
        this.email.value = this.$route.query.email
        this.email.isValid = true
        this.email.disabled = true
      }
      if (this.$route.query.code) {
        this.invitation_token.value = this.$route.query.code
      }
    }
  },
  methods: {
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container' : 'input-container --error'
    },
    // eslint-disable-next-line no-unused-vars
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    handleChoice(value, id) {
      this.company.value = {
        ...this.company.value,
        name: value,
        id,
      }
      this.company.isValid = true
    },
    signUp() {
      const {
        firstName: { value: firstName },
        lastName: { value: lastName },
        dayOfBirth: { value: dateOfBirth },
        email: { value: email },
        password: { value: password },
        company: {
          value: { id: companyId },
        },
      } = this

      if (this.invitation_token.value) {
        const invitationToken = this.invitation_token.value
        this.$apollo
          .mutate({
            mutation: auth.signUp,
            variables: {
              email,
              password,
              invitationToken
            },
          })
          .then(() => {
            this.signedUp = true
          })
          .catch(console.error)
      } else {
        this.$apollo
          .mutate({
            mutation: auth.signUp,
            variables: {
              firstName,
              lastName,
              dateOfBirth,
              email,
              password,
              companyId,
            },
          })
          .then(() => {
            this.signedUp = true
          })
          .catch((e) => {
            for (const [key, value] of Object.entries(e.graphQLErrors[0].extensions.validation)) {
              this[key].isValid = false;
              this[key].feedback = value[0];
            }
          })
      }
    },
  },
}
</script>
