<template>
  <Element :is="tag" ref="scrollbox" class="scrollbox">
    <Element :is="contentTag" :class="contentClass">
      <slot />
    </Element>
  </Element>
</template>

<script>
import scroll from 'scroll'
import SimpleBar from 'simplebar'

export default {
  name: 'ScrollBox',
  inject: {
    scrollBoxContext: { default: Object },
  },
  provide() {
    const { scopeName, scrollToTop, scrollToBottom, scrollToTarget, scrollingElement } = this
    const scrollBoxParent = { scrollingElement, scrollToTop, scrollToBottom, scrollToTarget }
    const scrollBoxContext = scopeName
      ? { ...this.scrollBoxContext, [scopeName]: scrollBoxParent }
      : this.scrollBoxContext

    return { scrollBoxContext, scrollBoxParent }
  },
  props: {
    tag: { type: String, default: 'div' },
    scopeName: { type: String, default: null },
    contentTag: { type: String, default: 'div' },
    contentClass: { type: [String, Array, Object], default: Array },
    emitScrollEvent: { type: Boolean, default: false },
    simplebarConfig: { type: Object, default: Object },
    target: { type: String, default: null },
  },
  data() {
    return {
      simplebarInstance: null,
      removeScrollEventListener: () => {},
      scrollingElement: document.scrollingElement || document.documentElement,
    }
  },
  watch: {
    target: {
      immediate: true,
      handler(target) {
        if (target) this.scrollToTarget(target)
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.simplebarInstance = new SimpleBar(this.$refs.scrollbox, this.simplebarConfig)
      this.scrollingElement = this.simplebarInstance.getScrollElement()
      if (this.emitScrollEvent) this.createScrollEventEmitter()
      this.$emit('mounted', this.scrollingElement)
    })
  },
  beforeDestroy() {
    this.removeScrollEventListener()
  },
  methods: {
    createScrollEventEmitter() {
      let isQueued = false

      const emitScrollEvent = () => {
        this.$emit('scroll', this.scrollingElement)
        isQueued = false
      }

      const throttleEventEmitter = () => {
        if (isQueued) return
        window.requestAnimationFrame(emitScrollEvent)
        isQueued = true
      }

      this.scrollingElement.addEventListener('scroll', throttleEventEmitter)
      this.removeScrollEventListener = () => this.scrollingElement.removeEventListener('scroll', throttleEventEmitter)
    },
    scrollToTop(offset = 0, duration) {
      if (duration) return scroll.top(this.scrollingElement, 0 + offset, { duration })
      this.scrollingElement.scrollTop = 0 + offset
    },
    scrollToBottom(offset = 0, duration) {
      const { scrollHeight = 0 } = this.scrollingElement
      if (duration) return scroll.top(this.scrollingElement, scrollHeight + offset, { duration })
      this.scrollingElement.scrollTop = scrollHeight + offset
    },
    scrollToTarget(target, offset = 0, duration) {
      const targetElement = typeof target === 'string' ? this.scrollingElement.querySelector(target) : target
      if (!targetElement) return

      targetElement.scrollIntoView()
    },
  },
}
</script>
