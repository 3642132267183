<template>
  <div class="alpha-group">
    <h2 class="alpha-group__title">{{ letter }}</h2>
    <ul class="alpha-group__children">
      <InductionItem
        v-for="induction in inductions"
        :key="induction.id"
        class="alpha-group__child"
        :induction="induction"
      />
    </ul>
  </div>
</template>

<script>
import InductionItem from '@/components/_parts/Inductiontem.vue'

export default {
  name: 'AlphaGroup',
  components: {
    InductionItem,
  },
  props: {
    letter: {
      type: String,
      required: true,
    },
    inductions: {
      type: Array,
      required: true,
    },
  },
}
</script>
