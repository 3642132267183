<template>
  <RouterView v-if="isReady"/>
</template>

<script>
export default {
  name: 'AuthRoot',
  data() {
    return {
      isReady: false
    }
  },
  watch: {
    '$store.state.user.preferred_lang'(to) {
      this.$i18n.locale = to
    }
  },
  beforeMount() {
    // check if already authenticated
    if (!localStorage.getItem('access_token')) {
      this.$router.push({name: 'signin'})
      // return
    }
    this.$store
      .dispatch('verifyCurrentSession')
      .then(() => {
        if (this.$store.getters.role) {
          const {name: pathName} = this.$route
          const {role} = this.$store.getters
          if (role !== "contractor" && !pathName.startsWith(role)) {
            this.$router.push({name: this.$store.getters.role})
          }

          this.isReady = true
        }
      })
      .catch(() => {
        this.$router.push({name: 'signin'})
      })
  }
}
</script>

<style></style>
