<template>
  <div class="lang-dropdown-wrapper" :style="wrapperStyles">
    <div class="lang-dropdown">
      <div v-if="isOpen" class="lang-dropdown__content">
        <ul class="lang-dropdown__content__results">
          <li
            class="lang-dropdown__content__results__result"
            :class="{'-selected': currentLang === 'en'}"
            @click="switchLang('en')"
          >EN
          </li>
          <li
            class="lang-dropdown__content__results__result"
            :class="{'-selected': currentLang === 'fr'}"
            @click="switchLang('fr')"
          >FR
          </li>
          <li
            class="lang-dropdown__content__results__result"
            :class="{'-selected': currentLang === 'es'}"
            @click="switchLang('es')"
          >ES
          </li>
        </ul>
      </div>

      <div class="lang-dropdown__initial-content" @click.prevent="toggleOpen">
        <div class="lang-dropdown__label">{{ label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/gql/auth'
import headers from '@/helpers/headers'

export default {
  name: 'LangSwitcherDropdown',
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    label() {
      return this.currentLang.toUpperCase()
    },
    arrowIconClass() {
      return [
        'material-icons',
        {
          rotated: this.isOpen
        }
      ]
    },
    wrapperStyles() {
      return this.isOpen ? {'z-index': 999} : null
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
    close(e) {
      if (this.isOpen && !this.$el.contains(e.target)) {
        this.isOpen = false
      }
    },
    updateUserLang(lang) {
      this.$apollo.mutate({
        mutation: auth.updateUser,
        context: {
          headers,
        },
        variables: {
          id: this.$store.state.user.id,
          preferredLang: lang
        }
      })
        .then(resp => {
          this.$store.commit('setUser', resp.data.updateUser)
        })
    },
    switchLang(lang) {
      this.$i18n.locale = lang
      this.close({target: null})
      this.updateUserLang(lang)
    }
  }
}
</script>
