<template>
  <div v-if="$apollo.queries.site.loading">Loading Info...</div>
  <div v-else>
    <div class="slide-sidemenu__content__buttons">
      <button
        v-if="isEdit"
        :disabled="!emailIsValid"
        class="slide-sidemenu__content__save-button button--regular"
        @click="handleSave()"
      >
        Save
      </button>
      <button v-if="isEdit" class="slide-sidemenu__content__save-button button--outlined" @click="handleCancel()">
        Cancel
      </button>
    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Name</h5>
      <input
        v-if="isEdit"
        v-model="site.name"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ site.name }}</p>
    </div>
    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Address</h5>
      <input
        v-if="isEdit"
        v-model="site.address"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ site.address }}</p>
    </div>
    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Email</h5>
      <input
        v-if="isEdit"
        v-model="site.email"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ site.email }}</p>
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Managers</h5>
      <div v-for="manager in site.active_managers" :key="manager.id" class="slide-sidemenu__content__profile-content__field-relative">
        <DeleteModale :left="true" v-if="deleteModal === manager.id" :site-name="site.name" :site-manager="`${manager.first_name} ${manager.last_name}`" @cancel="deleteModal = null" @confirm="deleteManager"/>
        <p class="slide-sidemenu__content__profile-content__field-content">
        {{ manager.first_name }} {{ manager.last_name }}
        </p>
        <button @click="$globalEvents.emit('open-menu', 'siteManager', manager.id)">
          <i class="material-icons">remove_red_eye</i>
        </button>
        <button @click="deleteModal = manager.id">
          <i class="material-icons">delete</i>
        </button>
      </div>
      <p v-if="site.active_managers.length === 0" class="slide-sidemenu__content__profile-content__field-content">
        This site doesn't have a manager yet.
      </p>
      <div>
        <button @click="$globalEvents.emit('edit-site-manager', site.id)">
          <i class="material-icons">edit</i>
        </button>
      </div>
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Manager History</h5>
      <div v-if="site.managers && site.managers.length > 0">
        <div
          v-for="year in years"
          :key="year"
          class="slide-sidemenu__content__profile-content__field-content list-item"
        >
          <span class="mr-5">
            {{ year }}
          </span>
          <div class="slide-sidemenu__content__profile-content--flex">
            <div class="mb-10 w-100" v-for="(manager, index) in managersForYear(year)" :key="`${year}-${index}`">
              {{ manager.first_name }} {{ manager.last_name }}
            </div>
          </div>
        </div>
      </div>
      <p v-else>This site doesn't have a manager history for this year.</p>
    </div>
  </div>
</template>

<script>
import siteGQL from '@/gql/site'
import years from '@/helpers/years'
import headers from '@/helpers/headers'
import validationRegex from '@/helpers/validationRegex'
import DeleteModale from "@/components/layout/DeleteModale.vue";

export default {
  name: 'SiteSideMenu',
  components: {DeleteModale},
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      site: {},
      deleteModal: null
    }
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear()
      const firstYear = years.startYear;

      return Array(currentYear - firstYear + 1)
          .fill(firstYear)
          .map((year, index) => year + index)
    },
    emailIsValid() {
      return validationRegex.email.test(this.site.email)
    },
    history() {
      return this.site.managers;
    },
  },
  methods: {
    managersForYear (year) {
      return this.history.filter(manager =>
          years.getYear(manager.managing_dates.created_at) <= year
          && (
              !manager.managing_dates.deleted_at
              || years.getYear(manager.managing_dates.deleted_at) >= year
              )
      )
    },
    deleteManager() {
      const id = this.deleteModal;
      this.$apollo
          .query({
            query: siteGQL.detachManager,
            variables: {
              siteId: this.site.id,
              managerId: id,
            },
            context: {
              headers,
            },
          })
          .then(() => {
            const index = this.site.active_managers.findIndex(manager => manager.id === id);
            this.site.active_managers.splice(index, 1);
            this.deleteModal = null;
          })
    },
    handleSave() {
      this.saveSite()
    },
    handleCancel() {
      this.$emit('save')
    },
    getFullYear(date) {
      return years.getYear(date)
    },
    saveSite() {
      const {
        site: { id: siteId, email, name, address },
      } = this

      this.$apollo
        .mutate({
          mutation: siteGQL.updateSite,
          variables: {
            siteId,
            email,
            name,
            address,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          this.$emit('save')
        })
        .catch(console.error)
    },
  },
  apollo: {
    site: {
      query: siteGQL.getSiteProfile,
      variables() {
        return {
          id: this.id,
        }
      },
      context: {
        headers,
      },
      update: data => data.site,
    },
  },
}
</script>
