<template>
  <div>
    <template v-if="optionsModel.values.length">
      <div v-for="(value, index) in optionsModel.values" :key="index" class="input-edit-container">
        <input
          :id="`${questionId}-${index}`"
          type="checkbox"
          :name="`question-${questionId}`"
          :value="value.text"
          @change="handleAnswerChange($event, index)"
        />
        <label :for="`${questionId}-${index}`" class="input">
          <i :class="getIconClass(value.valid)">{{ getInputIcon(value.valid) }}</i>
          <input
            v-model="optionsModel.values[index].text"
            class="ml-10"
            type="text"
            placeholder="Type an option..."
            @input="handleInput"
          />
        </label>
      </div>
    </template>
    <div class="input-edit-actions mt-50">
      <button class="button button--outlined" @click="addOption">+</button>
      <button class="button button--outlined ml-5" @click="removeOption">-</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleEdit',
  props: {
    options: {
      type: Object,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      optionsModel: { ...this.options },
    }
  },
  watch: {
    options() {
      this.optionsModel = { ...this.options }
    }
  },
  methods: {
    addOption() {
      this.optionsModel.values = [
        ...this.optionsModel.values,
        {
          text: '',
          valid: false,
        },
      ]
    },
    removeOption() {
      const valuesCopy = [...this.optionsModel.values]
      const newValues = valuesCopy.slice(0, -1)
      this.optionsModel.values = [...newValues]
    },
    handleInput() {
      this.$emit('single-change', this.optionsModel)
    },
    handleAnswerChange({ target }, index) {
      const newValues = [...this.optionsModel.values]

      if (target.checked) {
        newValues[index].valid = !newValues[index].valid
      }

      this.optionsModel.values = [...newValues]
      this.$emit('single-change', this.optionsModel)
    },
    getInputIcon(isValid) {
      return isValid ? 'done' : 'horizontal_rule'
    },
    getIconClass(isValid) {
      return [
        'material-icons',
        {
          green: isValid,
        },
      ]
    },
  },
}
</script>
