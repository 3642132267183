import gql from 'graphql-tag'

// QUERIES

// MUTATIONS

const createQuestion = gql`
  mutation CreateQuestion($quizId: Int!, $order: Int!, $text: Json!, $note: Json!, $options: Json!) {
    createFormQuizQuestion(input: { quiz_id: $quizId, order: $order, text: $text, note: $note, options: $options }) {
      id
      quiz_id
      order
      text
      note
      options
    }
  }
`

const updateQuestion = gql`
  mutation UpdateQuestion($id: ID!, $order: Int!, $text: Json, $note: Json, $options: Json) {
    updateFormQuizQuestion(id: $id, input: { order: $order, text: $text, note: $note, options: $options }) {
      id
      quiz_id
      order
      text
      note
      options
    }
  }
`

const deleteQuestion = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteFormQuizQuestion(id: $id) {
      id
    }
  }
`

const quizGQL = {
  createQuestion,
  updateQuestion,
  deleteQuestion,
}

export default quizGQL
