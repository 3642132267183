<template>
  <div class="mobile-profile">
    <button class="mobile-profile__back" @click="$store.commit('closeManagerMobileMenu')">
      <i class="material-icons">keyboard_arrow_left</i>
    </button>
    <div class="mobile-profile__title">
      <h2 class="mb-10">{{ contractor.first_name }} {{ contractor.last_name }}</h2>
      <h3>Contractor</h3>
    </div>
    <div class="mobile-profile__content mt-50">
      <div class="basic-details">
        <div>
          <h4>Company Name</h4>
          <p>{{ company.name }}</p>
        </div>
        <div>
          <h4>Day of Birth</h4>
          <p>{{ contractor.date_of_birth }}</p>
        </div>
      </div>
      <div class="status">
        <div>
          <h4>Current Status</h4>
          <div>
            <p class="mb-5">Induction completed on</p>
            <strong>{{ induction.completed_at }}</strong>
          </div>
        </div>
        <div class="status__on-site mt-25">
          <div>
            <p class="mb-5">On-Site Induction is</p>
            <strong>{{ status }}</strong>
          </div>
          <div>
            <SwitchButton :checked="onSiteTrainingDone" @input="handleToggleCompleted" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from '@/components/_parts/SwitchButton.vue'
import inductionsGQL from '@/gql/inductions'
import headers from '@/helpers/headers'

export default {
  name: 'MobileProfile',
  components: {
    SwitchButton,
  },
  data() {
    return {
      induction: this.$store.state.managerSelectedInduction,
    }
  },
  computed: {
    onSiteTrainingDone() {
      return this.induction.on_site_training_done
    },
    status() {
      return this.onSiteTrainingDone ? 'Complete' : 'Incomplete'
    },
    statusClass() {
      return this.onSiteTrainingDone ? 'green' : ''
    },
    contractor() {
      return this.induction.user
    },
    company() {
      return this.contractor.companies[0]
    },
  },
  methods: {
    handleToggleCompleted() {
      const { id } = this.induction

      this.$apollo
        .mutate({
          mutation: inductionsGQL.markInductionOnSiteCompleted,
          variables: {
            id,
          },
          context: {
            headers,
          },
        })
        .then(({ data }) => {
          this.induction.on_site_training_done = data.markInductionOnSiteCompleted.on_site_training_done
        })
        .catch(console.error)
    },
  },
}
</script>
