import inductionsGQL from '@/gql/inductions'
import apollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'

export default function ({ commit }) {
  return new Promise((res, rej) => {
    const { instance: $apollo } = apollo

    $apollo
      .mutate({
        mutation: inductionsGQL.editForm,
        context: {
          headers,
        },
      })
      .then(resp => {
        commit('setForm', resp.data.editForm)
        res()
      })
      .catch(err => {
        rej(err)
      })
  })
}
