<template>
  <div class="quick-actions__action-block">
    <div :class="arrowClass"/>
    <div v-if="$device.phone" class="quick-actions__action-block__mobile-title mt-25">
      <h3 class="mb-15">{{ card }}</h3>
      <p>{{ cardText }}</p>
    </div>
    <button class="button button--x" @click="$emit('close')">
      <i class="material-icons">close</i>
    </button>
    <component
      :is="actionComponent"
      class="quick-actions__form-wrapper"
      :site-id="selectedSiteId"
      @close="$emit('close')"
      @add-manager="handleAddManager"
    />
  </div>
</template>

<script>
import {quickActionTypesComponentMap} from '@/store/constants'

export default {
  name: 'ActionBlock',
  props: {
    card: {
      type: String,
      required: true
    },
    cardText: {
      type: String,
      required: true
    },
    formSiteId: {
      type: Number,
      required: false
    },
    isManager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      siteId: null
    }
  },
  computed: {
    actionComponent() {
      return quickActionTypesComponentMap[this.card]
    },
    selectedSiteId() {
      return this.siteId || this.formSiteId
    },
    arrowClass() {
      if (this.isManager) return 'quick-actions__action-block-arrow-manager'
      if (this.$device.phone) return ''
      switch (this.actionComponent.name) {
        case 'SiteManagerForm':
          return 'quick-actions__action-block-arrow-manager'
        case 'CompanyForm':
          return 'quick-actions__action-block-arrow-company'
        case 'SiteForm':
          return 'quick-actions__action-block-arrow-site'
        default:
          return ''
      }
    }
  },
  methods: {
    handleAddManager(id) {
      this.$emit('add-manager')
      this.siteId = id
    }
  }
}
</script>
