import contractorGQL from '@/gql/contractor'
import apollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'

export default function ({}, id) {
  return new Promise((res, rej) => {
    const { instance: $apollo } = apollo

    $apollo
      .mutate({
        mutation: contractorGQL.resendCertificate,
        context: {
          headers,
        },
        variables: {
          id
        },
      })
      .then(resp => {
        res(resp)
      })
      .catch(err => {
        rej(err)
      })
  })
}
