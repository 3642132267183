// export default function (state, section) {
//   state.form.sections.forEach((stateSection, index) => {
//     if (stateSection.order === section.order) {
//       state.form.sections[index] = section
//     }
//   })
// }
export default function (state, section) {
  const atIndex = state.form.sections.findIndex(({ order }) => order === section.order)
  state.form.sections = [...state.form.sections.slice(0, atIndex), section, ...state.form.sections.slice(atIndex + 1)]
}
