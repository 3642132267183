import ManagerDashboard from '@/components/pages/Dashboards/ManagerDashboard.vue'

const ManagerRoot = {
  name: 'ManagerRoot',
  render: h => h('router-view'),
  routes: [
    {
      path: '',
      name: 'manager',
      component: ManagerDashboard,
    },
  ],
}

export { ManagerRoot }
