<template>
  <div :class="{'modale__relative': !left}">
    <div v-if="!left" class="modale__arrow"/>
    <div class="modale" :class="{'-left': left}">
      <p class="modale__center">Are you sure you want to remove this manager from this site? The action won't delete
        their profile.</p>
      <p class="mt-25 modale__center"><strong>{{ siteName }}</strong></p>
      <p class="mt-10 modale__center thin">{{ siteManager }}</p>
      <div class="modale__buttons mt-25">
        <button class="button button--outlined" @click="$emit('cancel')">Cancel</button>
        <button class="button button--regular" @click="$emit('confirm')">Confirm</button>
      </div>
    </div>
    <div v-if="left" class="modale__arrow -right"/>
  </div>
</template>

<script>
export default {
  name: "DeleteModale",
  props: {
    siteName: String,
    siteManager: String,
    left: Boolean
  }
}
</script>

<style scoped>

</style>