export default function (state, newOrder) {
  const withNewOrderAttr = newOrder.map((section, index) => {
    return {
      ...section,
      order: index + 2,
    }
  })

  state.form.sections = [state.form.sections[0], ...withNewOrderAttr]
}
