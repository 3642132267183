<template>
  <div class="manager-result-table">
    <div v-if="$device.desktop" class="table-header">
      <p @click="changeSort('last_name')">Contractor Name
        <SortArrow column-name="last_name" :sort-column="sortColumn" :sort-order="sortOrder"/>
      </p>
      <p @click="changeSort('company')">Company
        <SortArrow column-name="company" :sort-column="sortColumn" :sort-order="sortOrder"/>
      </p>
      <p>Date of Completion - Online Orientation</p>
      <p>On-Site training Status</p>
    </div>
    <div class="table-container">
      <ul class="letters">
        <li
          v-for="(value, name) in alphabeticallyGroupedContractors"
          :key="`${name}-key`"
          :class="letterClass(name)"
          @click="scrollToAnchor(name)"
        >
          {{ name }}
        </li>
      </ul>

      <ScrollBox ref="scrollingParent" class="table" :target="scrollTarget">
        <template v-for="(value, name) in alphabeticallyGroupedContractors">
          <AlphaGroup :id="name" :key="name" :ref="name" :letter="name" :inductions="value.inductions"/>
        </template>
      </ScrollBox>
    </div>
  </div>
</template>

<script>
import inductionsGQL from '@/gql/inductions'
import headers from '@/helpers/headers'
import AlphaGroup from '@/components/_parts/AlphaGroup.vue'
import ScrollBox from '@/components/layout/ScrollBox.vue'
import SortArrow from '@/components/_parts/SortArrow.vue'

export default {
  name: 'InductionsSearchTable',
  components: {
    AlphaGroup,
    ScrollBox,
    SortArrow
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    siteId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      inductions: [],
      scrollTarget: null,
      scrollKey: 'A',
      sortColumn: 'last_name',
      sortOrder: 'asc'
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    alphabeticallyGroupedContractors() {
      const data = this.inductions
        .filter(induction => {
          const search = this.search.toLowerCase()
          const fullName = `${induction.user.first_name} ${induction.user.last_name} ${induction.user.companies[0]?.name}`
          return fullName.toLowerCase().indexOf(search) > -1
        })
        .reduce((r, e) => {
          // get first letter of name of current element
          // const group = e.last_name[0]
          const [[group]] = this.sortColumn === 'company' ?
            e.user.companies[0]?.name.toUpperCase() :
            e.user[this.sortColumn].toUpperCase()
          // if there is no property in accumulator with this letter create it
          if (!r[group]) r[group] = {group, inductions: [e]}
          // if there is push current element to children array for that letter
          else r[group].inductions.push(e)

          // return accumulator
          return r
        }, {})

      Object.keys(data).forEach((group) => {
        data[group].inductions.sort((a,b) => {
          let reverse = 1
          if (this.sortColumn === 'last_name' && this.sortOrder === 'desc') {
            reverse = -1
          }
          const nameA = `${a.user.last_name} ${a.user.first_name}`.toUpperCase()
          const nameB = `${b.user.last_name} ${b.user.first_name}`.toUpperCase()
          if (nameA < nameB) {
            return -1 * reverse
          }
          if (nameA > nameB) {
            return reverse
          }
          return 0
        })
      })

      const sorted = Object.keys(data).sort()
      if (this.sortOrder === 'desc') {
        sorted.reverse()
      }

      // return data
      return sorted.reduce(
        (acc, key) => ({
          ...acc,
          [key]: data[key]
        }),
        {}
      )
    }
  },
  watch: {
    siteId() {
      this.getInductions()
    }
  },
  created() {
    this.getInductions()
  },
  methods: {
    changeSort(column) {
      if (column === this.sortColumn) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortColumn = column
        this.sortOrder = 'asc'
      }
    },
    scrollToAnchor(key) {
      this.scrollTarget = `#${key}`
      this.scrollKey = key
    },
    letterClass(name) {
      return [
        'mb-10 mt-10 letters__letter',
        {
          selected: name === this.scrollKey
        }
      ]
    },
    getInductions() {
      this.$apollo
        .query({
          query: inductionsGQL.inductions,
          variables: {
            site_id: this.siteId,
            year: this.currentYear
          },
          context: {
            headers
          }
        })
        .then(({data}) => {
          this.inductions = data.inductions
          this.$store.state.currentInductions = data.inductions
        })
        .catch(console.error)
    }
  }
}
</script>
