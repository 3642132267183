<template>
  <div class="search-dropdown-wrapper" :style="wrapperStyles">
    <div class="search-dropdown">
      <div v-if="isOpen" class="search-dropdown__content" :class="{'-large' : large}">
        <div v-if="!isYear || !isManager" class="search-dropdown__content__input-container">
          <input :id="inputId" v-model="search" type="text" name="search" />
          <i class="material-icons">search</i>
        </div>
        <ul v-if="isYear" class="search-dropdown__content__results">
          <li
            v-for="year in years"
            :key="year"
            class="search-dropdown__content__results__result"
            @click="handleYearChoice(year)"
          >
            {{ year }}
          </li>
        </ul>
        <ul v-else-if="isManager" class="search-dropdown__content__results">
          <li
              v-for="(site, key) in managerSites"
              :key="key"
              class="search-dropdown__content__results__result"
              @click="handleManagerChoice(site.name, site.id)"
          >
            {{ site.name }}
          </li>
        </ul>
        <ul v-else-if="isContractors" class="search-dropdown__content__results">
          <li
              v-for="(contractor, key) in contractors"
              :key="key"
              class="search-dropdown__content__results__result"
              @click="handleManagerChoice(contractor.name, contractor.id)"
          >
            {{ contractor.name }}
          </li>
        </ul>
        <ul v-else class="search-dropdown__content__results">
          <li v-if="$apollo.queries.data.loading">Loading...</li>
          <li
            v-for="object in data"
            v-else
            :key="object.id"
            class="search-dropdown__content__results__result"
            @click="handleChoice(object.name, object.id)"
          >
            {{ object.name }}
          </li>
        </ul>
      </div>

      <div class="search-dropdown__initial-content" :class="{'-large' : large}" @click.prevent="toggleOpen">
        <span class="search-dropdown__label">{{ value }}</span>
        <i :class="arrowIconClass">keyboard_arrow_down</i>
      </div>
    </div>
  </div>
</template>

<script>
// import gql from 'graphql-tag'
import searchQueryMap from '@/gql/search'
import headers from '@/helpers/headers'
import years from "@/helpers/years";

export default {
  name: 'SearchDropdown',
  props: {
    large: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Label',
    },
    gqlModel: {
      type: String,
      default: 'sites',
    },
    isYear: {
      type: Boolean,
      default: false,
    },
    isContractors: {
      type: Boolean,
      default: false
    },
    isManager: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false,
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    allLabel: {
      type: String,
      default: 'All',
    },
  },
  data() {
    return {
      isOpen: false,
      value: this.label,
      search: '',
      id: null,
      data: {},
    }
  },
  computed: {
    inputId() {
      return `${this.label}_id`
    },
    arrowIconClass() {
      return [
        'material-icons',
        {
          rotated: this.isOpen,
        },
      ]
    },
    wrapperStyles() {
      return this.isOpen ? { 'z-index': 999 } : null
    },
    contractors() {
      return this.data.filter(({id}) => {
        return !this.$store.state.currentInductions.find(({user}) => user.id === id)
      })
    },
    managerSites() {
      return this.$store.state.user.actives_managing_sites
    },
    years() {
      const currentYear = new Date().getFullYear()
      const firstYear = years.startYear;

      return Array(currentYear - firstYear + 1)
        .fill(firstYear)
        .map((year, index) => year + index)
    },
  },
  apollo: {
    data() {
      return {
        query: () => searchQueryMap[this.gqlModel],
        variables: () => {
          return {
            search: `${this.search}%`,
          }
        },
        context: {
          headers,
        },
        result({
          data: {
            [this.gqlModel]: { data: searchData },
          },
        }) {
          this.data = searchData.map(searchObj => {
            const { id } = searchObj
            return {
              id,
              name: !searchObj.name ? `${searchObj.first_name} ${searchObj.last_name}` : searchObj.name,
            }
          })
          if (this.allOption) {
            this.data.unshift({ id: null, name: this.allLabel })
          }
        },
        update: data => data[this.gqlModel],
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
    close(e) {
      if (this.isOpen && !this.$el.contains(e.target)) {
        this.isOpen = false
      }
    },
    handleChoice(value, id) {
      this.value = value
      this.id = id
      this.toggleOpen()
      this.$emit('input', this.value, this.id)
    },
    handleYearChoice(year) {
      this.value = year
      this.toggleOpen()
      this.$emit('input', [year, this.index])
    },
    handleManagerChoice(name, id) {
      this.value = name
      this.toggleOpen()
      this.$emit('input', id)
    },
  },
}
</script>
