<template>
  <div class="orientation">
    <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 1.5rem;">
      <div>
        <button
          class="button"
          :class="currentLang === 'en' ? 'button--outlined' : 'button--regular'"
          @click="switchFormLang('en')"
        >
          EN
        </button>
        <button
          class="button"
          :class="currentLang === 'fr' ? 'button--outlined' : 'button--regular'"
          @click="switchFormLang('fr')"
        >
          FR
        </button>
        <button
          class="button"
          :class="currentLang === 'es' ? 'button--outlined' : 'button--regular'"
          @click="switchFormLang('es')"
        >
          ES
        </button>
      </div>
      <button class="button button--regular" :disabled="publishLoading" @click="publish">
        Publish Orientation
      </button>
    </div>
    <div class="orientation__sections">
      <SectionEdit
        :is-intro="true"
        :current-lang="currentLang"
        :section="introSection"
        :opened-section-id="openedSectionId"
        @open-section="changeOpenedSection"
      />
      <draggable v-model="sectionsList" handle=".handle" @change="saveDrag">
        <SectionEdit
          v-for="section in sectionsList"
          :key="section.id"
          :current-lang="currentLang"
          :section="section"
          :opened-section-id="openedSectionId"
          @open-section="changeOpenedSection"
        />
      </draggable>
      <QuizEdit :current-lang="currentLang"/>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import SectionEdit from '@/components/layout/Orientation/SectionEdit.vue'
import QuizEdit from '@/components/layout/Orientation/QuizEdit.vue'
import orientationGQL from '@/gql/orientation'
import headers from '@/helpers/headers'

export default {
  name: 'OrientationEdit',
  components: {
    SectionEdit,
    draggable,
    QuizEdit,
  },
  data() {
    return {
      openedSectionId: null,
      publishLoading: false,
      currentLang: 'en'
    }
  },
  computed: {
    introSection() {
      return this.$store.state.form.sections[0]
    },
    sectionsList: {
      get() {
        return this.$store.state.form.sections.slice(1)
      },
      set(value) {
        this.$store.commit('reorderSections', value)
      },
    }
  },
  mounted() {
    this.currentLang = this.$i18n.locale
  },
  methods: {
    switchFormLang(lang) {
      this.currentLang = lang
    },
    changeOpenedSection(id) {
      this.openedSectionId = id
    },
    saveDrag({ moved: { element, newIndex } }) {
      const { id } = element
      const newOrder = newIndex + 2

      this.$apollo
        .mutate({
          mutation: orientationGQL.updateFormSectionOrder,
          variables: {
            id,
            order: newOrder,
          },
          context: {
            headers,
          },
        })
        .catch(console.error)
    },
    publish() {
      this.publishLoading = true
      this.$store.dispatch('publishForm', this.$store.state.form.id).then(() => {
        this.publishLoading = false
        this.$router.push({name: 'admin'})
        this.$store.commit('resetForm')
      }).catch(() => {
        this.publishLoading = false
      })
    }
  },
}
</script>
