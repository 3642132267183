import ContractorDashboard from '@/components/pages/Dashboards/ContractorDashboard.vue'
import ContractorLandingPage from '@/components/pages/ContractorLandingPage.vue'

const ContractorRoot = {
  name: 'ContractorRoot',
  render: h => h('router-view'),
  routes: [
    {
      path: '',
      name: 'contractor',
      component: ContractorLandingPage,
    },
    {
      path: 'orientation/:sectionId?',
      name: 'orientation',
      component: ContractorDashboard,
      props: true,
    },
  ],
}

export { ContractorRoot }
