<template>
  <div class="create-report">
    <h2 class="slim-title">Create a Report</h2>
    <div class="flex-card-container -wrap">
      <Card :title="cards[0].title" secondary>
        <h4 class="card__subtitle">{{ cards[0].subtitle }}</h4>
        <div class="card__dropdowns">
          <SearchDropdown label="Year" is-year :index="0" @input="handleYearInput" />
        </div>
        <button class="card__download" @click="downloadReport(0)">
          <i class="material-icons">save_alt</i>
        </button>
      </Card>
      <Card :title="cards[1].title" secondary>
        <h4 class="card__subtitle">{{ cards[1].subtitle }}</h4>
        <div class="card__dropdowns">
          <SearchDropdown label="Year" is-year :index="1" @input="handleYearInput" />
        </div>
        <button class="card__download" @click="downloadReport(1)">
          <i class="material-icons">save_alt</i>
        </button>
      </Card>
      <Card :title="cards[2].title" secondary>
        <h4 class="card__subtitle">{{ cards[2].subtitle }}</h4>
        <div class="card__dropdowns">
          <SearchDropdown label="Year" is-year :index="2" @input="handleYearInput" />
          <SearchDropdown label="Site" :all-option="true" all-label="All Sites" @input="handleSiteChoice" />
        </div>
        <button class="card__download" @click="downloadReport(2)">
          <i class="material-icons">save_alt</i>
        </button>
      </Card>
      <Card :title="cards[3].title" secondary>
        <h4 class="card__subtitle">{{ cards[3].subtitle }}</h4>
        <div class="card__dropdowns">
          <SearchDropdown label="Year" is-year :index="3" @input="handleYearInput" />
        </div>
        <button class="card__download" @click="downloadReport(3)">
          <i class="material-icons">save_alt</i>
        </button>
      </Card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import headers from '@/helpers/headers'
import Card from '@/components/_parts/Card.vue'
import SearchDropdown from '@/components/_parts/SearchDropdown.vue'

export default {
  name: 'CreateReport',
  components: {
    Card,
    SearchDropdown,
  },
  data() {
    return {
      cards: [
        {
          key: 'allSites',
          title: 'ALL SITES',
          subtitle: 'with site managers',
          fields: ['Year'],
          year: null,
          apiRoute: '/reports/sites',
        },
        {
          key: 'allContractorsInfo',
          title: 'ALL CONTRACTORS',
          subtitle: 'with info',
          fields: ['Year'],
          year: null,
          apiRoute: '/reports/contractors-by-year',
        },
        {
          key: 'allContractorsBySite',
          title: 'ALL CONTRACTORS',
          subtitle: 'for all sites or by specific site',
          fields: ['Year', 'Site'],
          year: null,
          siteId: null,
          apiRoute: '/reports/contractors-by-year-sites',
        },
        {
          key: 'allCompaniesByYear',
          title: 'ALL COMPANIES',
          subtitle: 'that have contractors',
          fields: ['Year'],
          year: null,
          apiRoute: '/reports/companies-by-year',
        }
      ],
    }
  },
  methods: {
    handleSiteChoice(_, id) {
      this.cards[2].siteId = id
    },
    handleYearInput([year, index]) {
      this.cards[index].year = year
    },
    downloadReport(index) {
      const baseUrl = `${process.env.VUE_APP_API_ENDPOINT}${this.cards[index].apiRoute}`
      const urlWithYear = `${baseUrl}?year=${this.cards[index].year}`
      const fullUrl = this.cards[index].siteId
        ? `${baseUrl}?year=${this.cards[index].year}&site_id=${this.cards[index].siteId}`
        : urlWithYear

      axios
        .get(fullUrl, {
          headers,
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.cards[index].key}-report.csv`)
          document.body.appendChild(link)
          link.click()
        })
    },
  },
}
</script>
