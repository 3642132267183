<template>
  <AuthLayout>
    <RouterLink v-if="$device.phone" class="auth-top-link" :to="{ name: 'signup' }">Sign up</RouterLink>
    <h1 class="mb-100">Sign In</h1>

    <div v-if="$route.params.changedPassword" class="auth-top__success">
      The password was successfully changed.
      <i class="material-icons">done</i>
    </div>

    <form action="" class="auth-block__form" @submit.prevent>
      <div :class="inputContainerClass(email)">
        <label for="email">Email</label>
        <input id="email" v-model="email.value" type="email" @change="validateInput('email', 'email')" />
        <p v-if="!email.isValid" class="input-container__feedback">
          {{ email.feedback }}
        </p>
      </div>
      <div :class="inputContainerClass(password)">
        <label for="password">Password</label>
        <input id="password" v-model="password.value" type="password" @input="validateInput('password', 'string')" />
        <p v-if="!password.isValid" class="input-container__feedback">
          {{ password.feedback }}
        </p>
      </div>
      <div class="auth-block__options">
        <div class="checkbox-container">
          <div class="checkbox bounce">
            <input id="remember" type="checkbox" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </div>
          <label for="remember">Remember me</label>
        </div>
        <RouterLink :to="{ name: 'passwordreset' }">Forgot password?</RouterLink>
      </div>
      <div class="auth-block__form__bottom">
        <RouterLink :to="{ name: 'signup' }">Sign up</RouterLink>
        <button :disabled="!email.value.length || !password.value.length" type="submit" class="button button--regular" @click="login">SIGN IN</button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout.vue'
import validateField from '@/helpers/validateField'
// import auth from '@/gql/auth'

export default {
  name: 'SingIn',
  components: {
    AuthLayout,
  },
  data() {
    return {
      email: {
        value: '',
        isValid: null,
        feedback: '',
      },
      password: {
        value: '',
        isValid: null,
        feedback: '',
      },
    }
  },
  computed: {
    allFieldsValid() {
      const fields = Object.values({ ...this.$data }).filter(field => field.isValid !== undefined)
      return fields.every(field => field.isValid)
    },
  },
  methods: {
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container' : 'input-container --error'
    },
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    login() {
      const {
        email: { value: email },
        password: { value: password },
      } = this

      this.$store.dispatch('login', { email, password }).then(() => {
        if (this.$store.getters.role) {
          this.$router.push({ name: this.$store.getters.role })
          window.location.reload()
        }
      }).catch(() => {
        this.email.isValid = false
        this.password.isValid = false
        this.password.feedback = 'You have entered an invalid username or password'
      })
    },
  },
}
</script>
