import years from "@/helpers/years";

const booleanToStatus = result => {
  if (typeof result !== 'boolean') return

  return result ? 'Complete' : 'Incomplete'
}

const formatTableData = (dataType, data) => {
  if (!dataType || !data || !data[dataType]) return

  if (dataType === 'sites') {
    return data.sites.map(site => {
      const { id, name: siteName, active_managers } = site
      return {
        id,
        siteName,
        managers: active_managers.map(manager => {
          return  {
            label: `${manager.first_name} ${manager.last_name}`,
            event: {
              name: 'open-menu',
              type: 'siteManagers',
              id: manager.id,
            }
          }
        }),
      }
    })
  }
  if (dataType === 'contractors') {
    return data.contractors.map(contractor => {
      const {
        id,
        first_name: firstName,
        last_name: lastName,
        companies,
        inductions,
        is_contractor: isContractor,
      } = contractor

      const currentCompany = companies ? companies[companies.length - 1].name : null
      const latestInduction = inductions.length > 0 ? inductions[inductions.length - 1] : null
      const filteredInductions = inductions.filter(induction => years.getYear(induction.completed_at) === years.getYear())
      const status = () => {
        if (filteredInductions.length === 0) return false;
        let answer = true;
        filteredInductions.forEach(induction => {
          if (!induction.on_site_training_done) {
            answer = false;
          }
        })
        return answer;
      }
      const amount = filteredInductions.filter(induction => induction.on_site_training_done).length;

      return isContractor
        ? {
            id,
            contractorName: `${firstName} ${lastName}`,
            currentCompany,
            dateOfCompletedInduction: latestInduction ? years.getYearMonthDay(latestInduction.completed_at) : '-',
            onSiteTrainingDone: inductions.length > 0
                ? {
                  isStatus: true,
                  status: booleanToStatus(status()),
                  total: filteredInductions.length,
                  amount: status() ? filteredInductions.length : amount
                }
                : '-',
          }
        : null
    })
  }
  if (dataType === 'companies') {
    return data.companies.map(company => {
      const { name, id } = company
      return {
        id,
        name,
      }
    })
  }
  if (dataType === 'siteManagers') {
    return data.siteManagers.map(manager => {
      const { id, first_name: firstName, last_name: lastName, actives_managing_sites: sites } = manager
      return {
        id,
        name: `${firstName} ${lastName}`,
        sites: sites.map(site => {
          return {
            label: site.name,
            event: {
              name: 'open-menu',
              type: 'sites',
              id: site.id,
            }
          }
        })
      }
    })
  }
}

export default formatTableData
