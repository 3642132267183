<template>
  <div class="orientation-section -edit">
    <span class="dot"></span>
    <span class="side-stripe">
      <span class="line"></span>
    </span>
    <header class="orientation-section__title" @click="toggleOpenSection">
      <h2 v-if="!isEdit">{{ sectionIndex + 1 }}. {{ sectionModel.title[currentLang] }}</h2>
      <span v-if="isEdit" class="orientation-section__title-input" @click.stop>
        {{ sectionIndex + 1 }}.
        <input v-model="sectionModel.title[currentLang]" type="text" placeholder="Section Title" />
      </span>

      <div class="orientation-section__edit-actions">
        <button v-if="sectionModel.id !== 1" class="orientation-section__edit-icon" @click.stop="isModalOpen = true">
          <i class="material-icons">delete_outline</i>
        </button>
        <button v-if="sectionModel.id !== 1" class="orientation-section__edit-icon handle">
          <i class="material-icons">open_with</i>
        </button>
        <button v-if="!isEdit" class="orientation-section__edit-icon" @click.stop="editSection">
          <i class="material-icons">edit</i>
        </button>
        <button v-if="isEdit && !sectionModel.isNew" class="button button--regular" @click.stop="saveChanges">
          {{ isSaving ? 'Saving...' : 'Save changes' }}
        </button>
        <button v-if="isEdit && sectionModel.isNew" class="button button--regular" @click.stop="saveNewSection">
          Create new section
        </button>
      </div>
    </header>
    <template v-if="isOpen">
      <!-- REFACTOR OF THIS SECTION MIGHT BE NECESSARY -->
      <div v-if="sectionModel.image && !chosenImageUrl" class="orientation-section__image">
        <img :src="sectionModel.image.url" alt="" />
        <div v-if="isEdit" class="orientation-section__file-input">
          <input id="file-upload" type="file" @change="putImageInState" />
          <label for="file-upload"><i class="material-icons">edit</i> Edit cover image</label>
        </div>
      </div>
      <div v-else-if="chosenImageUrl" class="orientation-section__image">
        <img :src="chosenImageUrl" alt="" />
        <div v-if="isEdit" class="orientation-section__file-input">
          <input id="file-upload" type="file" @change="putImageInState" />
          <label for="file-upload"><i class="material-icons">edit</i> Edit cover image</label>
        </div>
      </div>
      <div v-else-if="!chosenImageUrl && isEdit" class="orientation-section__image orientation-section__image--empty">
        <div class="orientation-section__file-input">
          <input id="file-upload" type="file" @change="putImageInState" />
          <label for="file-upload"><i class="material-icons">add</i> Add cover image</label>
        </div>
      </div>
      <div v-if="!isEdit" class="orientation-section__content" v-html="sectionModel.content[currentLang]">
        <div class="orientation-section__file-input">
          <input id="file-upload" type="file" @change="putImageInState" />
          <label for="file-upload"><i class="material-icons">edit</i> Edit cover image</label>
        </div>
      </div>

      <editor v-else v-model="sectionModel.content[currentLang]" tinymce-script-src="https://cdn.jsdelivr.net/npm/tinymce@5/tinymce.min.js" :init="editorConfig" />

      <div v-if="isIntro" >
        <div v-if="isEdit" class="orientation-section__video">
          <label>
            Youtube video ID
            <input type="text" v-model="youtubeId" class="orientation-section__video--input">
          </label>
        </div>
        <div v-else-if="section.youtube_id">
          <Youtube :id="section.youtube_id" />
        </div>
      </div>
    </template>
    <div class="add-section">
      <div class="add-section__bar"></div>
      <button class="add-section__add" @click="addSection"><i class="material-icons">add</i></button>
      <div class="add-section__bar"></div>
    </div>
    <div v-if="isModalOpen" class="orientation-section__modal">
      <p class="mb-25">Are you sure you want to delete this section?</p>
      <div class="orientation-section__modal__actions">
        <button class="button button--outlined orientation-section__modal__button" @click="isModalOpen = false">
          Cancel
        </button>
        <button class="button button--regular orientation-section__modal__button ml-10" @click="deleteSection">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import orientationGQL from '@/gql/orientation'
import headers from '@/helpers/headers'
import Youtube from "@/components/Youtube.vue";

export default {
  name: 'SectionEdit',
  components: {
    Youtube,
    editor: Editor,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    openedSectionId: {
      type: Number,
      default: null,
    },
    isIntro: {
      type: Boolean,
      default: false,
    },
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      isSaving: false,
      isEdit: false,
      isModalOpen: false,
      chosenImage: null,
      chosenImageUrl: null,
      chosenVideo: null,
      chosenVideoUrl: null,
      sectionModel: this.section,
      youtubeId: null,
      editorConfig: {
        content_css: '/css/app.css',
        body_class: 'orientation-section__content',
        height: 500,
        plugins: [
          'link lists preview hr anchor pagebreak image wordcount fullscreen directionality paste textpattern imageresizing',
        ],
        toolbar: 'undo redo | h1 h2 h3 | bold italic hr fullscreen | align | bullist numlist link image',
        placeholder: 'Enter the section content here.',
        relative_urls: false,
        menubar: false,
        use_lfm: false,
        images_upload_handler: this.uploadTinyMceMedia,
        images_reuse_filename: true,
        file_picker_types: 'image',
        image_description: false,
        inline_styles: true,
        setup: this.installTinyMcePlugins,
      },
    }
  },
  computed: {
    sectionIndex() {
      return this.$store.state.form.sections.findIndex(section => section.id === this.sectionModel.id)
    },
    tinyMceKey() {
      return process.env.VUE_APP_TINY_MCE_KEY
    },
    isOpen() {
      return this.section.id === this.openedSectionId
    },
  },
  mounted() {
    if (this.sectionModel.isNew) {
      this.isEdit = true
    }
    this.youtubeId = this.section.youtube_id
  },
  methods: {
    installTinyMcePlugins() {
      window.tinymce.PluginManager.add('imageresizing', function (editor) {
        editor.on('ObjectResizeStart', function (e) {
          if (e.target.nodeName === 'IMG') {
            const selectedImage = window.tinymce.activeEditor.selection.getNode()
            window.tinymce.activeEditor.dom.setStyle(selectedImage, 'width', e.width)
            window.tinymce.activeEditor.dom.setStyle(selectedImage, 'height', e.height)

            selectedImage.removeAttribute('width')
            selectedImage.removeAttribute('height')
          }
        })
      })
    },
    toggleOpenSection() {
      if (this.isOpen) {
        this.isEdit = false
        this.$emit('open-section', null)
      } else {
        this.$emit('open-section', this.section.id)
      }
    },
    putImageInState({ target: { files = [] } }) {
      this.chosenImage = files
      this.chosenImageUrl = URL.createObjectURL(files[0])
    },
    editSection() {
      this.$emit('open-section', this.section.id)
      this.isEdit = true
    },
    addSection() {
      const { order } = this.sectionModel
      this.$store.commit('createSection', { order, index: this.sectionIndex })
    },
    saveNewSection() {
      this.$store.dispatch('saveNewSection', { section: this.sectionModel, image: this.chosenImage }).then(() => {
        this.isEdit = false
      })
    },
    deleteSection() {
      this.$store.dispatch('deleteSection', this.sectionModel).then(() => (this.isModalOpen = false))
    },
    saveChanges() {
      this.isSaving = true
      const { id, order, title, content, form_id: formId } = this.sectionModel

      this.$apollo
        .mutate({
          mutation: orientationGQL.updateFormSection,
          variables: {
            id,
            order,
            title: JSON.stringify(title),
            content: JSON.stringify(content),
            formId,
            youtubeId: this.youtubeId,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          this.sectionModel = Object.assign(this.sectionModel, res.data.updateFormSection, { isNew: false })
          if (this.chosenImage) {
            this.uploadNewMedia(res.data.updateFormSection.id, 'image', this.chosenImage[0])
              .then(() => {
                this.isSaving = false
                this.isEdit = false
              })
              .catch(console.error)
          } else {
            this.isSaving = false
            this.isEdit = false
          }
        })
        .catch(console.error)
    },
    uploadTinyMceMedia(blob, success, failure) {
      this.$apollo
        .mutate({
          mutation: orientationGQL.uploadMediaTinyMce,
          variables: {
            media: blob.blob(),
          },
          context: {
            headers,
            hasUpload: true,
          },
        })
        .then(({ data }) => {
          success(data.uploadMediaTinyMce.url)
        })
        .catch(err => {
          failure(err)
        })
    },
    uploadNewMedia(id, type, media) {
      return new Promise((res, rej) => {
        this.$apollo
          .mutate({
            mutation: orientationGQL.uploadMediaFormSection,
            variables: {
              id,
              type,
              media,
            },
            context: {
              headers,
              hasUpload: true,
            },
          })
          .then(() => {
            res()
          })
          .catch(err => {
            rej(err)
          })
      })
    },
  },
}
</script>
