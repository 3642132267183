<template>
  <label class="switch">
    <input type="checkbox" :checked="checked" @input="onChange($event.target.checked)" />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onChange(checked) {
      this.$emit('input', checked)
    },
  },
}
</script>
