<template>
  <div class="logged-in-user">
    <span>{{ loggedInUser }}</span> |
    <RouterLink :to="{ name: 'logout' }"><i class="material-icons">login</i></RouterLink>
  </div>
</template>

<script>
export default {
  name: 'LoggedInUser',
  props: {
    loggedInUser: {
      type: String,
      default: 'User',
    },
  },
}
</script>
