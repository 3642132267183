<template>
  <div class="auth-page">
    <div class="container">
      <div class="app-description">
        <!-- APP DESCRIPTION -->
        <img src="@/assets/images/oldcastle_infrastructure_logo_primary_white.png" alt="Old Castle logo" />
        <div v-if="$device.desktop" class="app-description__description">
          <h2>Online Contractor Orientation</h2>
          <div class="app-description__description__content">
            <p>
              At Oldcastle, we put safety first.
            </p>
            <ul>
              <li>For us at Oldcastle Infrastructure, safety is a core value. Our goal is to achieve ZERO HARM at our sites to ensure all individuals go home in the same condition in which they arrived. We protect each other, look out for one another, and when we see something, we stop and do something.</li>
              <li>We strongly encourage everyone to take time and make safety a part of their lifestyle no matter the environment. </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="auth-block">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>
