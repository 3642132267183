<template>
  <div class="app">
    <header v-if="$device.desktop">
      <Nav />
    </header>
    <header v-if="$device.phone && ($store.getters.role === 'contractor' || $store.getters.role === 'manager')">
      <ContractorMobileNav />
    </header>
    <main :class="containerClass">
      <div class="container__content">
        <slot />
      </div>
      <SlideSideMenu />
    </main>
    <header v-if="$device.phone && $store.getters.role === 'admin'">
      <AdminMobileNav />
    </header>
  </div>
</template>

<script>
import Nav from './Nav.vue'
import SlideSideMenu from '@/components/_parts/SlideSideMenu.vue'
import AdminMobileNav from '@/components/layout/AdminMobileNav.vue'
import ContractorMobileNav from '@/components/layout/ContractorMobileNav.vue'

export default {
  name: 'LoggedInLayout',
  components: {
    Nav,
    SlideSideMenu,
    AdminMobileNav,
    ContractorMobileNav,
  },
  computed: {
    containerClass() {
      return [
        'container',
        {
          'container__menu-open': this.$store.state.isAdminMenuOpen,
        },
      ]
    },
  },
}
</script>
