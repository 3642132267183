<template>
  <div class="orientation">
    <div class="orientation__sections">
      <Section
        v-for="(section, index) in $store.state.form.sections"
        :key="section.id"
        :class="`section-${index}`"
        :index="index + 1"
        :section="section"
        @scrollTop="scrollTop"
      />
      <Quiz/>
    </div>
    <PassingBlock v-if="$store.state.isQuizPassed"/>
  </div>
</template>

<script>
import Section from '@/components/layout/Orientation/Section.vue'
import Quiz from '@/components/layout/Orientation/Quiz.vue'
import PassingBlock from '@/components/layout/Orientation/PassingBlock.vue'

export default {
  name: 'Orientation',
  components: {
    Section,
    Quiz,
    PassingBlock
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    }
  },
  methods: {
    scrollTop() {
      if (this.$store.state.isOrientationFinished) {
        document.querySelector('.quiz').scrollIntoView()
      } else {
        document.querySelector(`.section-${this.$store.state.activeOrientationSectionIndex}`).scrollIntoView()
      }
    }
  }
}
</script>
