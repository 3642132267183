import inductionsGQL from '@/gql/inductions'
import apollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'

export default function ({}, id) {
  return new Promise((res, rej) => {
    const { instance: $apollo } = apollo

    $apollo
      .mutate({
        mutation: inductionsGQL.publishForm,
        variables: { id },
        context: {
          headers,
        },
      })
      .then(() => {
        res()
      })
      .catch(err => {
        rej(err)
      })
  })
}
