<template>
  <div>
    <div v-for="option in options" :key="`value-${option.text}-checkbox`" class="checkbox-container mb-15">
      <div v-if="!isAnswered" class="checkbox bounce">
        <input :id="`question-${question.id}-${option.text}`" v-model="model[option.text]" type="checkbox" />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
        </svg>
      </div>
      <i v-if="isAnswered" :class="feedBackClass(option.text, true)">{{ feedBackIcon(option.text) }}</i>
      <label :class="feedBackClass(option.text)" :for="`question-${question.id}-${option.text}`">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Multiple',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: Array,
    },
    answer: {
      type: Array,
      default: Array,
    },
    choice: {
      type: Array,
      default: Array,
    },
    isAnswered: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      model: {},
    }
  },
  computed: {
    options() {
      return this.question.options[this.currentLang].values
    },
    selectedOptions() {
      return Object.keys(this.model).filter(key => this.model[key])
    },
  },
  watch: {
    selectedOptions(newValue) {
      this.$emit('input', newValue)
    },
  },
  methods: {
    isSelected(text) {
      return this.choice.includes(text)
    },
    feedBackIcon(label) {
      if (this.isAnswered && this.isValid && this.isSelected(label)) return 'done'
      if (this.isAnswered && !this.isValid) return 'horizontal_rule'

      return ''
    },
    feedBackClass(label, isIcon = false) {
      return {
        'material-icons': isIcon,
        'ml-10': !isIcon,
        green: this.isAnswered && this.isValid && this.isSelected(label),
      }
    },
  },
}
</script>
