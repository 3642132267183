<template>
    <div class="youtube">
      <iframe
          start
          class="youtube__player"
          :src="`${baseUrl}${id}`"
          frameborder="0"
          allowfullscreen
      />
    </div>
</template>

<script>

export default {
  name: "Youtube",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      baseUrl: 'https://www.youtube.com/embed/'
    }
  }
}
</script>
