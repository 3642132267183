<template>
  <div>
    <!-- STEP 1 -->
    <div v-if="currentStep === 1" class="quick-actions__form-wrapper__step">
      <h4>1. Add site info</h4>

      <div class="quick-actions__form-wrapper__step__inputs">
        <div :class="inputContainerClass(name)">
          <label for="companyName">Name</label>
          <input id="companyName" v-model="name.value" type="text" @input="validateInput('name', 'string')" />
          <p v-if="!name.isValid" class="input-container__feedback">
            {{ name.feedback }}
          </p>
        </div>
        <div :class="inputContainerClass(email)">
          <label for="companyEmail">Email</label>
          <input id="companyEmail" v-model="email.value" type="email" @input="validateInput('email', 'email')" />
          <p v-if="!email.isValid" class="input-container__feedback">
            {{ email.feedback }}
          </p>
        </div>
      </div>
      <div :class="inputContainerClass(address)">
        <label for="companyAddress">Address</label>
        <input id="companyAddress" v-model="address.value" type="text" @input="validateInput('address', 'string')" />
        <p v-if="!address.isValid" class="input-container__feedback">
          {{ address.feedback }}
        </p>
      </div>

      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--regular" :disabled="!allFieldsValid" @click="createSite">Confirm</button>
      </div>
    </div>

    <!-- STEP 2 -->
    <div v-if="currentStep === 2" class="quick-actions__form-wrapper__step">
      <h4>2. Review site info</h4>
      <!-- ICONE CHECKED -->
      <p class="mb-10"><i class="material-icons">done</i></p>

      <p class="strong">
        <strong>{{ name.value }}</strong>
      </p>
      <p class="slim">{{ email.value }}</p>
      <p class="mt-15">{{ reviewMessage }}</p>

      <div class="quick-actions__form-wrapper__step__buttons">
        <div>
          <button class="button button--outlined" @click="$emit('close')">Close window</button>
        </div>
        <div class="ml-15">
          <button class="button button--regular" @click="$emit('add-manager', id)">Add manager</button>
          <button class="button button--regular" @click="seeSite">See site profile</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import siteGQL from '@/gql/site'
import headers from '@/helpers/headers'
import validateField from '@/helpers/validateField'

export default {
  name: 'SiteForm',
  data() {
    return {
      currentStep: 1,
      name: {
        value: '',
        isValid: null,
        feedback: '',
      },
      email: {
        value: '',
        isValid: null,
        feedback: '',
      },
      address: {
        value: '',
        isValid: null,
        feedback: '',
      },
      id: null,
      siteManager: null,
    }
  },
  computed: {
    isConfirmedDisabled() {
      const isCompleted = this.name && this.email && this.address
      return !isCompleted
    },
    reviewMessage() {
      return this.siteManager !== null
        ? this.siteManager.name
        : 'Has been added as a site. Eventually, a site manager has to be linked to it.'
    },
    allFieldsValid() {
      return this.name.isValid && this.email.isValid && this.address.isValid
    },
  },
  methods: {
    next() {
      this.currentStep++
    },
    seeSite() {
      this.$globalEvents.emit('open-menu', 'site', this.id)
    },
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container mt-10' : 'input-container --error mt-10'
    },
    createSite() {
      const {
        name: { value: name },
        email: { value: email },
        address: { value: address },
      } = this

      this.$apollo
        .mutate({
          mutation: siteGQL.createSite,
          variables: {
            name,
            email,
            address,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          const {
            data: {
              createSite: { id },
            },
          } = res
          this.id = id
          this.next()
        })
        .catch(console.error)
    },
  },
}
</script>
