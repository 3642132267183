import gql from 'graphql-tag'

// QUERIES

const currentUser = gql`
  query CurrentUser {
    me {
      id
      first_name
      last_name
      email
      preferred_lang
      is_admin
      is_site_manager
      is_contractor
      actives_managing_sites {
        id
        name
      }
      active_managing_site {
        id
        name
      }
      managingSites {
        id
        name
      }
      inductions {
        id
        year
        completed_at
        on_site_training_done
        site {
          id
          name
          address
        }
      }
    }
  }
`

const updateUser = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $dateOfBirth: Date
    $email: String
    $password: String
    $companyId: Int
    $preferredLang: String
  ) {
    updateUser(
      id: $id,
      input: {
        first_name: $firstName
        last_name: $lastName
        date_of_birth: $dateOfBirth
        email: $email
        password: $password
        password_confirmation: $password
        company_id: $companyId
        preferred_lang: $preferredLang
      }
    ) {
      id
      first_name
      last_name
      email
      preferred_lang
      is_admin
      is_site_manager
      is_contractor
      actives_managing_sites {
        id
        name
      }
      active_managing_site {
        id
        name
      }
      managingSites {
        id
        name
      }
      inductions {
        id
        year
        completed_at
        on_site_training_done
        site {
          id
          name
          address
        }
      }
    }
  }
`

const userInductions = gql`
  query UserInductions {
    me {
      inductions {
        id
        year
        completed_at
        on_site_training_done
        site {
          id
          name
          address
        }
      }
    }
  }
`

// MUTATIONS

const login = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        first_name
        last_name
        email
        preferred_lang
        is_admin
        is_site_manager
        is_contractor
        active_managing_site {
          id
          name
        }
        inductions {
          id
          year
          completed_at
          on_site_training_done
          site {
            id
            name
            address
          }
        }
      }
    }
  }
`

const signUp = gql`
  mutation SignUp(
    $firstName: String
    $lastName: String
    $dateOfBirth: Date
    $email: String!
    $password: String!
    $companyId: Int
    $invitationToken: String
  ) {
    register(
      input: {
        first_name: $firstName
        last_name: $lastName
        date_of_birth: $dateOfBirth
        email: $email
        password: $password
        password_confirmation: $password
        company_id: $companyId
        invitation_token: $invitationToken
      }
    ) {
      message
    }
  }
`

const forgotPassword = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      message
    }
  }
`

const resetPassword = gql`
  mutation ResetPassword($password: String! $token: String! $email: String!) {
    resetPassword(
      input: {
        token: $token
        email: $email
        password: $password
        password_confirmation: $password
      }) {
      message
    }
  }
`

const verifyEmail = gql`
  mutation VerifyEmail($token: String) {
    verifyEmail(input: { token: $token }) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        first_name
        last_name
        email
        preferred_lang
        is_admin
        is_site_manager
        is_contractor
        active_managing_site {
          id
          name
        }
        inductions {
          id
          year
          completed_at
          on_site_training_done
          site {
            id
            name
            address
          }
        }
      }
    }
  }
`

const auth = {
  currentUser,
  updateUser,
  login,
  signUp,
  forgotPassword,
  resetPassword,
  userInductions,
  verifyEmail
}

export default auth
