<template>
  <div class="radio-button">
    <template v-for="(optionValue, index) of options.values">
      <div :key="`value-${index}`">
        <input
          v-if="!isAnswered"
          :id="`question-${question.id}-${index}`"
          type="radio"
          :name="`question-${question.id}`"
          :checked="value"
          class="radio-button__field"
        />
        <label :for="`question-${question.id}-${index}`" :class="labelClass" @click="$emit('input', optionValue.text)">
          <span :class="feedBackClass(optionValue.text)">{{ optionValue.text }}</span>
          <i :class="feedBackClass(optionValue.text, true)">{{ feedBackIcon(optionValue.text) }}</i>
        </label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Single',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    answer: {
      type: String,
      // required: true,
      default: null,
    },
    choice: {
      type: String,
      default: null,
    },
    isAnswered: {
      type: Boolean,
      required: true,
    },
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    options() {
      return this.question.options[this.currentLang]
    },
    labelClass() {
      return [
        'radio-button__label',
        'mb-10',
        {
          answered: this.isAnswered,
        },
      ]
    },
  },
  methods: {
    feedBackIcon(text) {
      if (this.isAnswered && this.choice === text && this.answer === this.choice) return 'done'
      if (this.isAnswered && this.choice === text && this.answer !== this.choice) return 'close'
      if (this.isAnswered && this.choice !== text) return 'horizontal_rule'
    },
    feedBackClass(text, isIcon = false) {
      return {
        'material-icons': isIcon,
        green: this.isAnswered && this.choice === text && this.answer === this.choice,
        red: this.isAnswered && this.choice === text && this.answer !== this.choice,
      }
    },
  },
}
</script>
