<template>
  <div :class="cardClassName">
    <h3 class="card__title">
      {{ title }}
    </h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: 'TITLE',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClassName() {
      return [
        'card',
        {
          'card--secondary': this.secondary === true,
          '-alt' : this.alt
        },
      ]
    },
  },
}
</script>
