<template>
  <div v-if="$apollo.queries.siteManager.loading">Loading info...</div>
  <div v-else>
    <div class="slide-sidemenu__content__buttons">
      <button
        v-if="isEdit"
        :disabled="!emailIsValid"
        class="slide-sidemenu__content__save-button button--regular"
        @click="handleSave()"
      >
        Save
      </button>
      <button v-if="isEdit" class="slide-sidemenu__content__save-button button--outlined" @click="handleCancel()">
        Cancel
      </button>
    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Manager Name</h5>
      <input
        v-if="isEdit"
        v-model="siteManager.fullName"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ siteManager.fullName }}</p>
    </div>
    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Email</h5>
      <input
        v-if="isEdit"
        v-model="siteManager.email"
        class="slide-sidemenu__content__profile-content__field-input"
        type="text"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ siteManager.email }}</p>
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Names</h5>
      <template v-if="siteManager.actives_managing_sites && Object.values(siteManager.actives_managing_sites).length > 0">
        <div v-for="site in siteManager.actives_managing_sites" :key="site.id" class="slide-sidemenu__content__profile-content__field-relative">
          <p class="slide-sidemenu__content__profile-content__field-content">
            {{ site.name }}
          </p>
          <button @click="$globalEvents.emit('open-menu', 'site', site.id)">
            <i class="material-icons">remove_red_eye</i>
          </button>
        </div>
      </template>
      <p v-else>-</p>
      <!-- <button><i class="material-icons">edit</i></button> -->
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site History</h5>
      <template v-if="siteManager.managingSites.length">
        <p
          v-for="(site, index) in sortHistory"
          :key="index"
          class="slide-sidemenu__content__profile-content__field-content list-item"
        >
          <span>{{ getYear(site.created_at) }}</span
          ><span>{{ site.name }}</span>
        </p>
      </template>
      <p v-else>-</p>
    </div>
  </div>
</template>

<script>
import siteManagerGQL from '@/gql/site-manager'
import headers from '@/helpers/headers'
import validationRegex from '@/helpers/validationRegex'
import years from '@/helpers/years'

export default {
  name: 'SiteManagerSideMenu',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      siteManager: {},
    }
  },
  computed: {
    emailIsValid() {
      return validationRegex.email.test(this.siteManager.email)
    },
    sortHistory() {
      const element = this.siteManager.managingSites;
      return element.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
  },
  methods: {
    getYear(date) {
      return years.getYear(date)
    },
    handleSave() {
      this.saveSiteManager()
    },
    handleCancel() {
      this.isEdit = false
      this.$emit('save')
    },
    saveSiteManager() {
      const {
        siteManager: { id, fullName, email },
      } = this

      const [firstName, lastName] = fullName.split(' ')

      this.$apollo
        .mutate({
          mutation: siteManagerGQL.updateManager,
          variables: {
            id,
            email,
            firstName,
            lastName,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          this.$emit('save')
        })
        .catch(console.error)
    },
    siteKey(id) {
      const randomKey = Math.floor(Math.random() * 14000) + id
      return randomKey
    },
  },
  apollo: {
    siteManager: {
      query: siteManagerGQL.getManagerProfile,
      variables() {
        return {
          id: this.id,
        }
      },
      context: {
        headers,
      },
      update: data => ({
        ...data.user,
        fullName: `${data.user.first_name} ${data.user.last_name}`,
      }),
    },
  },
}
</script>
