export default function (state) {
  if (!state.user) return
  if (state.is_admin) return

  let siteId

  if (state.user.is_site_manager) {
    siteId = state.user.active_managing_site.id
  }
  if (state.user.is_contractor) {
    siteId = state.user.inductions[state.user.inductions.length - 1].site.id
  }

  return siteId
}
