<template>
  <div id="app">
    <RouterView></RouterView>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isOverflow() {
      return !this.$store.state.isAdminMenuOpen
    }
  }
}
</script>
