<template>
  <span v-if="sortColumn===columnName">
    <i v-if="sortOrder==='asc'" class="material-icons">keyboard_arrow_down</i>
    <i v-if="sortOrder==='desc'" class="material-icons">keyboard_arrow_up</i>
  </span>
</template>

<script>
export default {
  name: 'SortArrow',
  props: {
    columnName: {
      type: String,
      required: true,
    },
    sortColumn: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: String,
      default: 'asc'
    }
  }
}
</script>
