let ApolloVM = {}

export default {
  install(Vue, provider) {
    ApolloVM = new Vue({
      name: 'ApolloVM',
      data() {
        return { provider }
      },
      created() {},
    })
  },

  get instance() {
    return ApolloVM.provider.defaultClient
  },
}
