import portableApollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'
import orientationGQL from '@/gql/orientation'

export default function ({ commit }, { section, image }) {
  const { instance: $apollo } = portableApollo

  return new Promise((res, rej) => {
    const { form_id: formId, order, title, content } = section

    // Regular section save
    $apollo
      .mutate({
        mutation: orientationGQL.createFormSection,
        variables: {
          formId,
          order,
          title: JSON.stringify(title),
          content: JSON.stringify(content),
        },
        context: {
          headers,
        },
      })
      .then(resp => {
        const { id } = resp.data.createFormSection

        // If image is chosen, save the image
        if (image) {
          $apollo
            .mutate({
              mutation: orientationGQL.uploadMediaFormSection,
              variables: {
                id,
                type: 'image',
                media: image[0],
              },
              context: {
                headers,
                hasUpload: true,
              },
            })
            .then(uploadRes => {
              commit('updateNewSectionState', uploadRes.data.uploadMediaFormSection)
              res()
            })
            .catch(err => {
              rej(err)
            })
        } else {
          commit('updateNewSectionState', resp.data.createFormSection)
          res()
        }
      })
      .catch(err => {
        rej(err)
      })
  })
}
