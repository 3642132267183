<template>
  <LoggedInLayout>
    <template v-if="$device.desktop">
      <QuickActions />
      <CreateReport />
      <AdminSearchBar />
    </template>
    <template v-if="$device.phone">
      <QuickActions v-if="$store.state.admin_current_section === 'quick-actions'" />
      <CreateReport v-if="$store.state.admin_current_section === 'reports'" />
      <AdminSearchBar v-if="$store.state.admin_current_section === 'search'" />
    </template>
  </LoggedInLayout>
</template>

<script>
import LoggedInLayout from '@/components/layout/LoggedInLayout.vue'
import QuickActions from '@/components/layout/QuickActions.vue'
import CreateReport from '@/components/layout/CreateReport.vue'
import AdminSearchBar from '@/components/layout/AdminSearchBar.vue'

export default {
  name: 'AdminDashboard',
  components: {
    LoggedInLayout,
    QuickActions,
    CreateReport,
    AdminSearchBar,
  },
  methods: {
    onButtonClick(type, id) {
      this.$globalEvents.emit('open-menu', type, id)
    },
  },
}
</script>
