// REFACTOR MIGHT BE NEEDED...
export default function (state, {order, index}) {
  const newSection = {
    content: {en: '', fr: '', es: ''},
    form_id: state.form.id,
    id: Math.floor(Math.random() * 1000) + 1000,
    image: null,
    image_id: null,
    order: order + 1,
    title: {en: '', fr: '', es: ''},
    video: null,
    video_id: null,
    isNew: true
  }

  // state.form.sections = [
  //   ...state.form.sections.slice(0, index + 1),
  //   newSection,
  //   ...state.form.sections.slice(index + 1),
  // ]

  state.form.sections = [
    ...state.form.sections.slice(0, index + 1),
    newSection,
    ...state.form.sections
      .slice(index + 1)
      .map(({order: sectionOrder, ...section}) => ({...section, order: sectionOrder + 1}))
  ]

  // for (let i = index + 2; i < state.form.sections.length; i += 1) {
  //   state.form.sections[i].order += 1
  // }
}
