<template>
  <div @click="handleClick">
    <h4 v-for="(value, key) in removeId(result)" :key="key">
      <span v-if="isMenuEvent(value)" class="clickable" @click="openMenu(value.event)"> {{ value.label }} </span>
      <span v-else-if="isArray(value)">
        <span v-for="subValue in value" :key="subValue.id" class="listElement" @click="openMenu(subValue.event)">
          <span class="clickable">{{ subValue.label }}</span>
        </span>
      </span>
      <span v-else-if="isObject(value) && value.isStatus" :class="booleanClass(value.status)">
        {{ value.status }} ({{ value.amount }}/{{ value.total }})
      </span>
      <span v-else-if="isObject(value)">
        <div v-for="(subValue, subKey) in Object.values(value)" :key="subKey">
          {{ subValue }}
        </div>
      </span>
      <span v-else :class="booleanClass(value)"> {{ value }} </span>
      <!-- <span></span> -->
    </h4>
    <button @click="$globalEvents.emit('open-menu', accountType, result.id)">
      <i v-if="$device.desktop" class="material-icons">remove_red_eye</i>
      <i v-if="$device.phone" class="material-icons">keyboard_arrow_right</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AdminResult',
  props: {
    result: {
      type: Object,
      required: true,
    },
    accountType: {
      type: String,
      required: true,
    },
  },
  methods: {
    booleanClass(value) {
      if (value === 'Done') return 'green'
      if (value === 'To do' || value === 'Incomplete') return 'red'
    },
    isBool(value) {
      return typeof value === 'boolean'
    },
    isMenuEvent(value) {
      return !!value.event
    },
    isObject(value) {
      return typeof value === 'object'
    },
    isArray(value) {
      return Array.isArray(value);
    },
    openMenu(event) {
      this.$globalEvents.emit('open-menu', event.type, event.id)
    },
    removeId(result) {
      const parsedResult = { ...result }

      delete parsedResult.id

      return parsedResult
    },
    handleClick() {
      if (!this.$device.phone) return
      this.$globalEvents.emit('open-menu', this.accountType, this.result.id)
    },
  },
}
</script>
