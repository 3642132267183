<template>
  <div>
    <!-- STEP 1 -->
    <div v-if="currentStep === 1" class="quick-actions__form-wrapper__step">
      <h4>1. Add company info</h4>

      <div class="quick-actions__form-wrapper__step__inputs">
        <div :class="inputContainerClass(name)">
          <label for="companyName">Name</label>
          <input id="companyName" v-model="name.value" type="text" @input="validateInput('name', 'string')" />
          <p v-if="!name.isValid" class="input-container__feedback">
            {{ name.feedback }}
          </p>
        </div>
        <div :class="inputContainerClass(email)">
          <label for="companyEmail">Email</label>
          <input id="companyEmail" v-model="email.value" type="email" @input="validateInput('email', 'email')" />
          <p v-if="!email.isValid" class="input-container__feedback">
            {{ email.feedback }}
          </p>
        </div>
      </div>

      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--regular" :disabled="!allFieldsValid" @click="addCompany">Confirm</button>
      </div>
    </div>

    <!-- STEP 2 -->
    <div v-if="currentStep === 2" class="quick-actions__form-wrapper__step">
      <h4>2. Review company info</h4>
      <!-- ICONE CHECKED -->
      <p class="mb-10"><i class="material-icons">done</i></p>

      <p class="strong">
        <strong>{{ name }}</strong>
      </p>
      <p class="slim">{{ email }}</p>
      <p class="mt-15">
        Has been added as a company. Info linked to it will appear<br />as soon as one of its contractor completes the
        induction.
      </p>

      <div class="quick-actions__form-wrapper__step__buttons">
        <button class="button button--outlined" @click="$emit('close')">Close window</button>
        <button class="button button--regular ml-15" @click="seeCompany">See company profile</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import headers from '@/helpers/headers'
import validateField from '@/helpers/validateField'

export default {
  name: 'CompanyForm',
  data() {
    return {
      currentStep: 1,
      name: {
        value: '',
        isValid: null,
        feedback: '',
      },
      email: {
        value: '',
        isValid: null,
        feedback: '',
      },
      id: null,
    }
  },
  computed: {
    isConfirmedDisabled() {
      const isCompleted = this.name && this.email
      return !isCompleted
    },
    allFieldsValid() {
      return this.name.isValid && this.email.isValid
    },
  },
  methods: {
    next() {
      this.currentStep++
    },
    validateInput(modelName, type) {
      const { isValid, feedback } = validateField(this[modelName].value, type)
      this[modelName].isValid = isValid
      this[modelName].feedback = feedback
    },
    inputContainerClass(model) {
      return model.isValid !== false ? 'input-container' : 'input-container --error'
    },
    seeCompany() {
      this.$globalEvents.emit('open-menu', 'company', this.id)
    },
    addCompany() {
      const {
        email: { value: email },
        name: { value: name },
      } = this

      this.$apollo
        .mutate({
          mutation: gql`
            mutation AddCompany($email: String!, $name: String!) {
              createCompany(input: { email: $email, name: $name }) {
                id
                name
                email
              }
            }
          `,
          variables: {
            email,
            name,
          },
          context: {
            headers,
          },
        })
        .then(res => {
          // Result
          const {
            data: {
              createCompany: { id, email: companyEmail, name: companyName },
            },
          } = res
          this.name = companyName
          this.email = companyEmail
          this.id = id
          this.$globalEvents.emit('reload-search-results', 'companies')

          this.next()
        })
        .catch(console.error)
    },
  },
}
</script>
