<template>
  <div class="orientation-section">
    <template v-if="$device.desktop">
      <span class="dot"></span>
      <span class="side-stripe">
        <span class="line"></span>
      </span>
    </template>
    <h2 class="orientation-section__title">
      <span>{{ index }}. {{ title }}</span>
      <span v-if="isOpen && !$store.state.isOrientationFinished"
        >{{ index }}/{{ $store.state.form.sections.length }}</span
      >
      <i v-else-if="section.hasBeenConsulted" class="material-icons">check_circle</i>
    </h2>
    <template v-if="isOpen && !$store.state.isOrientationFinished">
      <div v-if="section.image" class="orientation-section__image">
        <img :src="section.image.url" alt="" />
      </div>
      <div class="orientation-section__content" v-html="content" />
      <div v-if="section.video && section.youtube_id">
        <Youtube :id="section.youtube_id" />
      </div>
      <div class="orientation-section__actions">
        <button v-if="!isFirstSection" class="button button--outlined" @click="previous">{{$t("common.previous")}}</button>
        <button v-if="!isLastSection" class="button button--regular" @click="next">{{$t("common.next")}}</button>
        <button v-if="isLastSection" class="button button--regular button--center" @click="testKnowledge">{{$t("orientation.testButton")}}</button>
      </div>
    </template>
  </div>
</template>

<script>
import Youtube from "@/components/Youtube.vue";

export default {
  name: 'Section',
  components: {Youtube},
  props: {
    section: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    title() {
      return this.section.title[this.$i18n.locale]
    },
    content() {
      return this.section.content[this.$i18n.locale]
    },
    isFirstSection() {
      let isFirst = false
      this.$store.state.form.sections.forEach((storeSection, index) => {
        if (this.section.id === storeSection.id && index === 0) isFirst = true
      })
      return isFirst
    },
    isLastSection() {
      let isLast = false

      this.$store.state.form.sections.forEach((storeSection, index) => {
        if (this.section.id === storeSection.id && index === this.$store.state.form.sections.length - 1) isLast = true
      })

      return isLast
    },
    isOpen() {
      if (!this.$store.state.isOrientationStarted) return false
      return this.$store.getters.activeOrientationSection.id === this.section.id
    },
  },
  methods: {
    toggleOpenSection() {
      this.isOpen = !this.isOpen
    },
    async next() {
      await this.$store.commit('markSectionAsVisited', this.section.id)
      await this.$store.commit('changeSection', 1)
      this.$emit('scrollTop')
    },
    async previous() {
      await this.$store.commit('changeSection', -1)
      this.$emit('scrollTop')
    },
    async testKnowledge() {
      await this.$store.commit('markSectionAsVisited', this.section.id)
      await this.$store.commit('setOrientationFinished')
      this.$emit('scrollTop');
    },
  },
}
</script>
