import auth from '@/gql/auth'
import apollo from '@/plugins/portableApollo'
// import { resolve } from 'core-js/fn/promise'
// import store from '@/store/index'

export default function ({ commit }, { token }) {
  return new Promise((res, rej) => {
    const { instance: $apollo } = apollo

    $apollo
      .mutate({
        mutation: auth.verifyEmail,
        variables: {
          token,
        },
      })
      .then(
        ({
          data: {
            verifyEmail: { user, access_token: accessToken, refresh_token: refreshToken },
          },
        }) => {
          localStorage.setItem('access_token', accessToken)
          localStorage.setItem('refresh_token', refreshToken)
          commit('setUser', user)
          res()
        }
      )
      .catch(err => {
        rej(err)
      })
  })
}
