import gql from 'graphql-tag'

// QUERIES

const getManagerProfile = gql`
  query GetManagerProfile($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      actives_managing_sites {
        id
        name
      }
      managingSites {
        id
        name
        created_at
      }
    }
  }
`

// MUTATIONS

const createInvitation = gql`
  mutation CreateInvitation($firstName: String!, $lastName: String!, $email: String!, $siteId: Int!) {
    createInvitation(
      input: { first_name: $firstName, last_name: $lastName, email: $email, site_id: $siteId, role: "site-manager" }
    ) {
      id
      first_name
      last_name
      site_id
      role
    }
  }
`

const updateManager = gql`
  mutation UpdateManager($id: ID!, $email: String!, $firstName: String!, $lastName: String!) {
    updateUser(id: $id, input: { email: $email, first_name: $firstName, last_name: $lastName }) {
      id
      first_name
      last_name
      email
    }
  }
`

const siteManagerGQL = {
  createInvitation,
  getManagerProfile,
  updateManager,
}

export default siteManagerGQL
