<template>
  <nav class="admin-mobile-nav">
    <div class="admin-mobile-nav__logo">
      <img src="/images/oldcastle_logomark.png" alt="" />
    </div>
    <div class="admin-mobile-nav__controls">
      <button
        :class="['button', { selected: $store.state.admin_current_section === 'quick-actions' }]"
        @click="$store.commit('adminChangeSection', 'quick-actions')"
      >
        <i class="material-icons">miscellaneous_services</i>
      </button>
      <button
        :class="['button', { selected: $store.state.admin_current_section === 'reports' }]"
        @click="$store.commit('adminChangeSection', 'reports')"
      >
        <i class="material-icons">view_list</i>
      </button>
      <button
        :class="['button', { selected: $store.state.admin_current_section === 'search' }]"
        @click="$store.commit('adminChangeSection', 'search')"
      >
        <i class="material-icons">search</i>
      </button>
    </div>
    <div class="admin-mobile-nav__logout">
      <button class="button" @click="$router.replace({ name: 'logout' })">
        <i class="material-icons">power_settings_new</i>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AdminMobileNav',
}
</script>
