<template>
  <div class="radio-button">
    <input
      v-if="!isAnswered"
      :id="`question-${question.id}-1`"
      type="radio"
      :name="`question-${question.id}`"
      :checked="value"
      class="radio-button__field"
    />
    <label :for="`question-${question.id}-1`" :class="labelClass" @click="$emit('input', true)">
      <span
        :class="{
          green: isAnswered && answer === true && value === true,
          red: isAnswered && answer !== true && value === true,
        }"
        >{{ $t("orientation.quiz.true") }}</span
      >
      <i :class="feedBackIconClass(true)">{{ feedBackIcon(true) }}</i>
    </label>

    <input
      v-if="!isAnswered"
      :id="`question-${question.id}-2`"
      type="radio"
      :name="`question-${question.id}`"
      :checked="value === false"
      class="radio-button__field"
    />
    <label :for="`question-${question.id}-2`" :class="labelClass" @click="$emit('input', false)">
      <span
        :class="{
          green: isAnswered && answer === false && value === false,
          red: isAnswered && answer !== false && value === false,
        }"
        >{{ $t("orientation.quiz.false") }}</span
      >
      <i :class="feedBackIconClass(false)">{{ feedBackIcon() }}</i>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TrueFalse',
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: null,
    },
    isAnswered: {
      type: Boolean,
      required: true,
    },
    answer: {
      type: Boolean,
      // required: true,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    currentLang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    labelClass() {
      return [
        'radio-button__label',
        'mb-10',
        {
          answered: this.isAnswered,
        },
      ]
    },
  },
  methods: {
    feedBackIcon(boolType = false) {
      if (!boolType) {
        if (this.isAnswered && this.answer === false && this.value === false) return 'done'
        if (this.isAnswered && this.answer !== false && this.value === false) return 'close'
        if (this.isAnswered && this.answer !== this.value) return 'horizontal_rule'
      }
      if (this.isAnswered && this.answer === true && this.value === true) return 'done'
      if (this.isAnswered && this.answer !== true && this.value === true) return 'close'
      if (this.isAnswered && this.answer !== !this.value) return 'horizontal_rule'

      return ''
    },
    feedBackIconClass(boolType = false) {
      if (!boolType) {
        return [
          'material-icons',
          {
            green: this.isAnswered && this.answer === false && this.value === false,
            red: this.isAnswered && this.answer !== false && this.value === false,
          },
        ]
      }

      return [
        'material-icons',
        {
          green: this.isAnswered && this.answer === true && this.value === true,
          red: this.isAnswered && this.answer !== true && this.value === true,
        },
      ]
    },
  },
}
</script>
