<template>
  <LoggedInLayout class="contractor-landing">
    <template v-if="!currentInduction">
      <div class="contractor-landing__flex-layout">
        <div class="left">
          <h1>{{ $t("contractor.dashboard.title", {year: currentYear}) }}</h1>
          <h2 class="mt-25">
            {{ $t("contractor.dashboard.accessMessage", {year: currentYear}) }}
          </h2>
          <ul class="mt-50 mb-50 indication-list">
            <li v-for="(point, key) in $t('contractor.dashboard.bulletPoints')" :key="key">
              {{point}}
            </li>
          </ul>
          <RouterLink :to="{ name: 'orientation' }" class="button button--regular">
            {{ $t("common.start") }}
          </RouterLink>
        </div>
        <div v-if="$device.desktop" class="right">
          <img src="/images/landing-page-bg.png" alt="landing page background" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="contractor-landing__flex-layout">
        <div class="left">
          <h1>{{ $t("contractor.dashboard.completedTitle", {year: currentYear}) }}</h1>
          <div class="mt-25 mb-50">
            <h2>{{$t("contractor.dashboard.assignedSites", {plural: currentInductions.length > 1 ? 's' : ''})}}</h2>
          </div>
          <div v-for="induction in currentInductions" :key="induction.siteId" class="mt-25 mb-25 contractor-landing__site-show">
            <h3 class="mb-15">{{ induction.site.name }}</h3>
            <p>{{ induction.site.address }}</p>
          </div>
          <div>
            <div v-if="certificateSent" style="font-weight: 700">{{$t("contractor.dashboard.certificateSent")}}</div>
            <button v-else class="button button--outlined" @click="sendCertificate">{{$t("contractor.dashboard.certificateResend")}}</button>
          </div>
          <ContractorAddSite/>
        </div>
        <div class="right">
          <div class="img-link-container mb-25">
            <img src="/images/oldcastle-products.png" alt="" />
            <a href="https://oldcastleinfrastructure.com/product-markets/" target="_blank"
              >{{ $t("contractor.dashboard.explore") }} <i class="material-icons">arrow_forward</i></a
            >
          </div>
          <div class="img-link-container">
            <img src="/images/oldcastle-company.png" alt="" />
            <a href="https://oldcastleinfrastructure.com/about/" target="_blank"
              >{{ $t("contractor.dashboard.learn") }} <i class="material-icons">arrow_forward</i></a
            >
          </div>
        </div>
      </div>
    </template>
  </LoggedInLayout>
</template>

<script>
import LoggedInLayout from '@/components/layout/LoggedInLayout.vue'
import inductionsGQL from '@/gql/inductions'
import headers from '@/helpers/headers'
import ContractorAddSite from "@/components/layout/ContractorAddSite.vue";

export default {
  name: 'ContractorLandingPage',
  components: {
    ContractorAddSite,
    LoggedInLayout,
  },
  data() {
    return {
      isEdit: false,
      siteId: null,
      certificateSent: false
    }
  },
  computed: {
    currentInductions() {
      return this.$store.state.user.inductions.filter(induction => induction.year === this.currentYear)
    },
    currentYear() {
      return new Date().getFullYear()
    },
    justFinished() {
      if (!this.$route.params.justFinished) return
      return this.$route.params.justFinished
    },
    currentInduction() {
      return this.$store.state.user.inductions.find(induction => induction.year === this.currentYear)
      // return this.$store.state.user.inductions[this.$store.state.user.inductions.length - 1]
    },
  },
  methods: {
    handleSiteChoice(_, id) {
      this.siteId = id
    },
    saveNewSite() {
      const { id } = this.currentInduction
      const { siteId } = this

      this.$apollo
        .mutate({
          mutation: inductionsGQL.updateInductionSite,
          variables: {
            id,
            siteId,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          window.location.reload()
        })
        .catch(console.error)
    },
    sendCertificate() {
      const {id} = this.currentInduction
      this.$store.dispatch('resendCertificate', id).then(() => {
        this.certificateSent = true
      })
    }
  },
}
</script>
