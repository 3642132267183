import AdminDashboard from '@/components/pages/Dashboards/AdminDashboard.vue'
import EditOrientation from '@/components/pages/Dashboards/EditOrientation.vue'

const AdminRoot = {
  name: 'AdminRoot',
  render: h => h('router-view'),
  path: '',
  routes: [
    {
      path: '',
      name: 'admin',
      component: AdminDashboard,
    },
    {
      path: 'edit-orientation',
      name: 'admin/edit-orientation',
      component: EditOrientation,
    },
  ],
}

export { AdminRoot }
