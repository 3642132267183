<template>
  <li @click="handleTouch">
    <div class="alpha-group__child__content-block">
      {{ contractor.first_name }} <strong>{{ contractor.last_name }}</strong>
      <div v-if="$device.desktop">
        {{ contractor.date_of_birth }}
      </div>
      <div v-else class="mt-10">
        {{ company.name }}
      </div>
    </div>

    <div v-if="$device.desktop" class="alpha-group__child__content-block">
      {{ company.name }}
    </div>

    <div v-if="$device.desktop" class="alpha-group__child__content-block">
      {{ induction.completed_at }}
    </div>

    <div class="alpha-group__child__content-block">
      <!-- {{ child.inductions[child.inductions.length - 1].on_site_training_done }} -->
      <span :class="statusClass">{{ status | capitalize }}</span>
      <!-- <span v-else>Incompleted</span> -->
      <SwitchButton v-if="$device.desktop" :checked="onSiteTrainingDone" @input="handleCompletedChoice"/>
    </div>

    <div v-if="modalOpen" class="alpha-group__child__modal">
      <p class="alpha-group__child__modal__warning">
        Are you sure you want to make the following On-Site Induction
        <span :class="statusClass">{{ status }}</span> ?
      </p>
      <div class="alpha-group__child__modal__info mt-20 mb-20">
        <div>
          <p>
            {{ contractor.first_name }} <strong>{{ contractor.last_name }}</strong>
          </p>
          <p>{{ contractor.date_of_birth }}</p>
        </div>
        <div>
          <p>{{ company.name }}</p>
        </div>
      </div>
      <div class="alpha-group__child__modal__actions">
        <button class="button button--outlined" @click="handleModalClose">Cancel</button>
        <button class="button button--regular" @click="confirmChoice">Confirm</button>
      </div>
    </div>
  </li>
</template>

<script>
import SwitchButton from '@/components/_parts/SwitchButton.vue'
import {capitalize} from '@/helpers/capitalize'
import inductionsGQL from '@/gql/inductions'
import headers from '@/helpers/headers'

export default {
  name: 'InductionItem',
  components: {
    SwitchButton
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      return capitalize(value)
    }
  },
  props: {
    induction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalOpen: false
    }
  },
  computed: {
    onSiteTrainingDone() {
      return this.induction.on_site_training_done
    },
    status() {
      return this.onSiteTrainingDone ? 'complete' : 'incomplete'
    },
    statusClass() {
      return this.onSiteTrainingDone ? 'green' : ''
    },
    contractor() {
      return this.induction.user
    },
    company() {
      return this.contractor.companies[0]
    }
  },
  methods: {
    handleCompletedChoice(checked) {
      this.modalOpen = true
      this.induction.on_site_training_done = checked
    },
    handleModalClose() {
      this.modalOpen = false
      this.induction.on_site_training_done = !this.induction.on_site_training_done
    },
    confirmChoice() {
      const {id} = this.induction

      this.$apollo
        .mutate({
          mutation: inductionsGQL.markInductionOnSiteCompleted,
          variables: {
            id
          },
          context: {
            headers
          }
        })
        .then(({data}) => {
          this.modalOpen = false
          this.induction.on_site_training_done = data.markInductionOnSiteCompleted.on_site_training_done
        })
        .catch(console.error)
    },
    handleTouch() {
      if (!this.$device.phone) return
      this.$store.commit('openManagerMobileMenu', this.induction)
    }
  }
}
</script>
