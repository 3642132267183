<template>
  <LoggedInLayout>
    <div class="orientation-container">
      <div class="empty-child"></div>
      <OrientationEdit v-if="loaded" />
      <h1 v-else>LOADING...</h1>
    </div>
  </LoggedInLayout>
</template>

<script>
import LoggedInLayout from '@/components/layout/LoggedInLayout.vue'
import OrientationEdit from '@/components/layout/Orientation/OrientationEdit.vue'

export default {
  name: 'EditOrientation',
  components: {
    LoggedInLayout,
    OrientationEdit,
  },
  data() {
    return {
      loaded: false,
    }
  },
  mounted() {
    this.$store.dispatch('editForm').then(() => {
      this.loaded = true
    })
  },
}
</script>
