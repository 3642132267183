import AuthRoot from '@/components/pages/RootPages/AuthRoot.vue'
import SignIn from '@/components/pages/Auth/SignIn.vue'
import SignUp from '@/components/pages/Auth/SignUp.vue'
import PasswordReset from '@/components/pages/Auth/PasswordReset.vue'
import ResetPassword from "@/components/pages/Auth/ResetPassword.vue";
import { AdminRoot, ManagerRoot, ContractorRoot } from '@/components/pages/RootPages/index'
import EmailVerification from '@/components/pages/Auth/EmailVerification.vue'

const authenticatedRoutes = [
  {
    path: '',
    component: ContractorRoot,
    children: ContractorRoot.routes,
  },
  {
    path: '/admin',
    component: AdminRoot,
    children: AdminRoot.routes,
  },
  {
    path: '/manager',
    component: ManagerRoot,
    children: ManagerRoot.routes,
  },
]

const routes = [
  { path: '/signin', component: SignIn, name: 'signin' },
  { path: '/signup', component: SignUp, name: 'signup' },
  {
    path: '/logout',
    component: {
      name: 'logout',
      render: h => h('p'),
      beforeMount() {
        this.$store.dispatch('logout').then(() => {
          this.$router.replace({ name: 'signin' })
          window.location.reload()
        })
      },
    },
    name: 'logout',
  },
  { path: '/passwordreset', component: PasswordReset, name: 'passwordreset' },
  { path: '/reset-password', component: ResetPassword, name: 'resetpassword' },

  { path: '/email-verification', component: EmailVerification, name: 'email-verification' },
  {
    path: '/',
    component: AuthRoot,
    children: authenticatedRoutes,
  },
]

export default routes
