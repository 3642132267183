import gql from 'graphql-tag'

// QUERIES

// MUTATIONS

const createFormSection = gql`
    mutation CreateFormSection($formId: Int!, $order: Int!, $title: Json!, $content: Json!) {
createFormSection(input: { form_id: $formId, order: $order, title: $title, content: $content }) {
id
form_id
image_id
video_id
order
title
content
image {
id
original_name
url
}
video {
id
original_name
url
}
}
}
`

const updateFormSection = gql`
  mutation UpdateFormSection($id: ID!, $order: Int!, $formId: Int!, $title: Json!, $content: Json!, $youtubeId: String) {
    updateFormSection(id: $id, input: { form_id: $formId, order: $order, title: $title, content: $content, youtube_id: $youtubeId }) {
      id
      form_id
      order
      title
      content
      youtube_id
    }
  }
`

const updateFormSectionOrder = gql`
  mutation UpdateFormSectionOrder($id: ID!, $order: Int!) {
    updateFormSection(id: $id, input: { order: $order }) {
      id
      order
    }
  }
`

const deleteFormSection = gql`
  mutation DeleteFormSection($id: ID!) {
    deleteFormSection(id: $id) {
      id
    }
  }
`

const uploadMediaFormSection = gql`
  mutation UploadMediaFormSection($id: ID!, $type: String!, $media: Upload!) {
    uploadMediaFormSection(id: $id, type: $type, media: $media) {
      id
      form_id
      image_id
      video_id
      order
      title
      content
      image {
        id
        original_name
        url
      }
      video {
        id
        original_name
        url
      }
    }
  }
`

const uploadMediaTinyMce = gql`
  mutation UploadMediaTinyMce($media: Upload!) {
    uploadMediaTinyMce(media: $media) {
      url
    }
  }
`

const orientationGQL = {
  createFormSection,
  updateFormSection,
  deleteFormSection,
  updateFormSectionOrder,
  uploadMediaFormSection,
  uploadMediaTinyMce,
}

export default orientationGQL
