<template>
  <nav class="nav">
    <div class="nav__container">
      <div class="nav__container__logo">
        <img src="@/assets/images/oldcastle_infrastructure_logo_primary.png"/>
      </div>
      <div class="nav__container__actions">
        <RouterLink
          v-if="role === 'admin' && $route.name === 'admin'"
          :to="{ name: 'admin/edit-orientation' }"
          class="button button--regular"
        ><i class="material-icons">edit</i>Edit the orientation
        </RouterLink
        >
        <RouterLink
          v-if="role === 'admin' && $route.name === 'admin/edit-orientation'"
          class="button button--regular"
          :to="{ name: 'admin' }"
        >
          Back to dashboard
        </RouterLink>
        <LangSwitcherDropdown/>
        <LoggedInUser :logged-in-user="$store.getters.loggedInUser"/>
      </div>
    </div>
  </nav>
</template>

<script>
import LangSwitcherDropdown from '../_parts/LangSwitcherDropdown.vue'
import LoggedInUser from '../_parts/LoggedInUser.vue'

export default {
  name: 'Nav',
  components: {
    LangSwitcherDropdown,
    LoggedInUser
  },
  computed: {
    role() {
      return this.$store.getters.role
    }
  }
}
</script>
