import gql from 'graphql-tag'

const queries = {
  SITE_QUERY: gql`
    query SearchInputSites($search: String!) {
      sites(search: $search) {
        data {
          id
          name
        }
      }
    }
  `,
  COMPANY_QUERY: gql`
    query SearchInputCompanies($search: String!) {
      companies(search: $search) {
        data {
          id
          name
        }
      }
    }
  `,
  CONTRACTOR_QUERY: gql`
    query SearchInputContractors($search: String!) {
      contractors(search: $search) {
        data {
          id
          first_name
          last_name
        }
      }
    }
  `,
  MANAGER_QUERY: gql`
    query SearchInputManagers($search: String!) {
      siteManagers(search: $search) {
        data {
          id
          first_name
          last_name
        }
      }
    }
  `,
}

const searchQueryMap = {
  sites: queries.SITE_QUERY,
  companies: queries.COMPANY_QUERY,
  contractors: queries.CONTRACTOR_QUERY,
  siteManagers: queries.MANAGER_QUERY,
}

export default searchQueryMap
