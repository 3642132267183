/* eslint-disable no-unused-vars */
import portableApollo from '@/plugins/portableApollo'
import headers from '@/helpers/headers'
import quizGQL from '@/gql/quiz'

export default function ({ commit, state }, questions) {
  const { instance: $apollo } = portableApollo
  const { questions: stateQuestions } = state.form.quiz
  const calls = []

  stateQuestions.forEach(stateQuestion => {
    const exist = questions.find(q => q.id === stateQuestion.id)
    if (!exist) {
      calls.push(
        $apollo
          .mutate({
            mutation: quizGQL.deleteQuestion,
            variables: {
              id: stateQuestion.id,
            },
            context: {
              headers,
            },
          })
          .catch(console.error)
      )
    }
  })

  questions.forEach((question, index) => {
    const { id, note, options, order, quiz_id: quizId, text } = question
    if (!stateQuestions[index]) {
      calls.push(
        $apollo
          .mutate({
            mutation: quizGQL.createQuestion,
            variables: {
              quizId,
              order,
              text: JSON.stringify(text),
              note: JSON.stringify(note),
              options: JSON.stringify(options),
            },
            context: {
              headers,
            },
          })
          .catch(console.error)
      )
    } else {
      calls.push(
        $apollo
          .mutate({
            mutation: quizGQL.updateQuestion,
            variables: {
              id,
              order,
              text: JSON.stringify(text),
              note: JSON.stringify(note),
              options: JSON.stringify(options),
            },
            context: {
              headers,
            },
          })
          .catch(console.error)
      )
    }
  })

  return Promise.all(calls)
}
