import validationRegex from './validationRegex'
import validationFeedback from './validationFeedback'

export default function (value, type) {
  const feedbackObj = {
    isValid: false,
    feedback: '',
  }

  if (value) {
    if (validationRegex[type] && validationRegex[type].test(value)) {
      feedbackObj.isValid = true
    } else if (!validationRegex[type]) {
      feedbackObj.isValid = true
    } else {
      feedbackObj.isValid = false
      feedbackObj.feedback = validationFeedback[type].invalid
    }
  } else {
    feedbackObj.isValid = false
    feedbackObj.feedback = validationFeedback[type].empty
  }

  return feedbackObj
}
