import apollo from '@/plugins/portableApollo'
import auth from '@/gql/auth'
import headers from '@/helpers/headers'

export default function ({ commit }) {
  return new Promise((res, rej) => {
    const { instance: $apollo } = apollo

    $apollo
      .query({
        query: auth.currentUser,
        context: {
          headers,
        },
      })
      .then(resp => {
        commit('setUser', resp.data.me)
        res(resp)
      })
      .catch(err => {
        rej(err)
      })
  })
}
