var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radio-button"},[(!_vm.isAnswered)?_c('input',{staticClass:"radio-button__field",attrs:{"id":`question-${_vm.question.id}-1`,"type":"radio","name":`question-${_vm.question.id}`},domProps:{"checked":_vm.value}}):_vm._e(),_c('label',{class:_vm.labelClass,attrs:{"for":`question-${_vm.question.id}-1`},on:{"click":function($event){return _vm.$emit('input', true)}}},[_c('span',{class:{
        green: _vm.isAnswered && _vm.answer === true && _vm.value === true,
        red: _vm.isAnswered && _vm.answer !== true && _vm.value === true,
      }},[_vm._v(_vm._s(_vm.$t("orientation.quiz.true")))]),_c('i',{class:_vm.feedBackIconClass(true)},[_vm._v(_vm._s(_vm.feedBackIcon(true)))])]),(!_vm.isAnswered)?_c('input',{staticClass:"radio-button__field",attrs:{"id":`question-${_vm.question.id}-2`,"type":"radio","name":`question-${_vm.question.id}`},domProps:{"checked":_vm.value === false}}):_vm._e(),_c('label',{class:_vm.labelClass,attrs:{"for":`question-${_vm.question.id}-2`},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('span',{class:{
        green: _vm.isAnswered && _vm.answer === false && _vm.value === false,
        red: _vm.isAnswered && _vm.answer !== false && _vm.value === false,
      }},[_vm._v(_vm._s(_vm.$t("orientation.quiz.false")))]),_c('i',{class:_vm.feedBackIconClass(false)},[_vm._v(_vm._s(_vm.feedBackIcon()))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }